import React from 'react';
import { Show, SimpleShowLayout, FunctionField, ReferenceField } from 'react-admin';
import moment from 'moment';
import get from 'lodash/get';
import numeral from 'numeral';

const Actions = () => null;

const InvoicesShow = props => {

  return (
    <Show {...props} actions={<Actions />}>
      <SimpleShowLayout>
        <FunctionField label="Check in" render={record => moment(record.checkIn).format('DD-MM-YYYY HH:mm')} />
        <FunctionField label="Check out" render={record => moment(record.checkOut).format('DD-MM-YYYY HH:mm')} />
        <FunctionField label="Workspace" render={record => `${get(record, 'space.name')}`} />
        <FunctionField label="Room" render={record => get(record, 'resource.name', '')} />
        <FunctionField label="Price" render={record => `£${numeral(get(record, 'priceData.netAmountToPay')).format('0,0.00')}`} />
        <FunctionField label="Discount" render={record => `£${numeral(get(record, 'priceData.discount', 0)).format('0,0.00')}`} />
        <FunctionField label="Final price" render={record => `£${numeral(get(record, 'priceData.netAmountToPay') - get(record, 'priceData.discount',0)).format('0,0.00')}`} />
        <ReferenceField allowEmpty source="memberId" reference="members" sortable={false}>
          <FunctionField render={member => `${member.firstName} ${member.lastName}`} />
        </ReferenceField>

      </SimpleShowLayout>
    </Show>
  );
};


export default InvoicesShow;
