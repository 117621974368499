import React from 'react';
import { Create } from 'react-admin';
import AlertsForm from './AlertsForm';

const AlertsCreate = props => (
  <Create {...props}>
    <AlertsForm redirect="edit" />
  </Create>
);

export default AlertsCreate;
