import moment from 'moment';
import React from 'react';
import {
  DateInput,
  Filter,
  TextInput,
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  TextField,
  BooleanField,
} from 'react-admin';
import API from '../_app/api';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import { getTimezone } from '../utils';
import BulkExportButton from '../_common/components/BulkExportButton';

const BookingsActionButtons = props => (
  <>
    <BulkExportButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </>
)

const BookingsFilters = props => (
  <Filter {...props}>
    <TextInput source="title" />
    <DateInput source="from" alwaysOn label="From" />
    <DateInput source="to" alwaysOn label="To" />
    <ReferenceInput source="memberId" reference="members" label="Member" alwaysOn>
      <AutocompleteInput optionText={choice => `${choice.firstName} ${choice.lastName}`} />
    </ReferenceInput>
    <ReferenceInput
      source="resourceId"
      reference="resources"
      label="Meeting Room"
      filter={{ ...((API.defaults.params || {}).spaceId ? { spaceId: API.defaults.params.spaceId } : {}) }}
      alwaysOn
    >
      <AutocompleteInput optionText={choice => `${choice.name}`} />
    </ReferenceInput>
  </Filter>
);

const BookingsList = props => (
  <List {...props} filters={<BookingsFilters />} bulkActionButtons={<BookingsActionButtons />}>
    <Datagrid rowClick={(id, basePath, record) => (!record.isYoursBooking ? 'edit' : 'show')}>
      <FunctionField label="When" render={record => moment(record.checkIn).format('DD.MM.YYYY')} />
      <FunctionField label="Check in" render={record => `${moment(record.checkIn).format('HH:mm')} ${getTimezone()}`} />
      <FunctionField
        label="Check out"
        render={record => `${moment(record.checkOut).format('HH:mm')} ${getTimezone()}`}
      />
      <ReferenceField linkType={false} allowEmpty label="Meeting Room" source="resourceId" reference="resources" sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField linkType={false} allowEmpty source="memberId" reference="members" sortable={false}>
        <TextField source="email" />
      </ReferenceField>
      <TextField source="title" />
      <FunctionField
        source="recurringBookingId"
        label="Is recurring?"
        sortable={false}
        render={(record, source) => <BooleanField record={{ ...record, recurringBookingId: !!record.recurringBookingId }} source={source} />}
      />
    </Datagrid>
  </List>
);

export default BookingsList;
