import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, downloadCSV } from 'react-admin';
import get from 'lodash/get';
import pick from 'lodash/pick';
import values from 'lodash/values';
import ActionExport from '@material-ui/icons/GetApp';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const unselectAll = resource => ({
  type: 'RA/SET_LIST_SELECTED_IDS',
  payload: [],
  meta: {
    resource,
  },
});

class BulkExportButton extends Component {
  handleClick = () => {
    const { resource, selectedIds, records } = this.props;
    const csv = convertToCSV({
      data: values(pick(records, selectedIds)),
    });
    downloadCSV(csv, resource);
    this.props.unselectAll(resource);
  };

  render() {
    return (
      <Button label="Export" onClick={this.handleClick}>
        <ActionExport />
      </Button>
    );
  }
}

const mapStateToProps = (state, props) => ({
  records: get(state, `admin.resources[${props.resource}].data`, []),
});

export default connect(mapStateToProps, { unselectAll })(BulkExportButton);
