import React from 'react';
import { Create } from 'react-admin';
import PoliciesForm from './PoliciesForm';

const PoliciesCreate = props => (
  <Create {...props}>
    <PoliciesForm redirect="edit" />
  </Create>
);

export default PoliciesCreate;
