import moment from 'moment';
import numeral from 'numeral';
import { get } from 'lodash';
import React from 'react';
import {
  DateInput,
  Filter,
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { getTimezone } from '../utils';
import BulkExportButton from '../_common/components/BulkExportButton';


const ExternalBookingsFilters = props => (
  <Filter {...props}>
    <DateInput source="from" alwaysOn label="From" />
    <DateInput source="to" alwaysOn label="To" />
    <ReferenceInput source="memberId" reference="members" label="Member" alwaysOn>
      <AutocompleteInput
        optionText={choice => `${choice.firstName} ${choice.lastName}`}
      />
    </ReferenceInput>
  </Filter>
);

const ExternalBookingsActionButtons = props => (
  <>
    <BulkExportButton {...props} />
  </>
);

const ExternalBookingsList = props => (
  <List {...props} filters={<ExternalBookingsFilters />} bulkActionButtons={<ExternalBookingsActionButtons />}>
    <Datagrid rowClick={(id, basePath, record) => !record.isYoursBooking ? 'edit' : 'show'}>
      <FunctionField label="When" render={record => moment(record.checkIn).format('DD.MM.YYYY')} />
      <FunctionField label="Check in" render={record => `${moment(record.checkIn).format('HH:mm')} ${getTimezone()}`} />
      <FunctionField label="Check out" render={record => `${moment(record.checkOut).format('HH:mm')} ${getTimezone()}`} />
      <FunctionField label="Work.Life Space" render={record => get(record, 'space.name', 'N/A')} />
      <FunctionField label="Room" render={record => get(record, 'resource.name', 'N/A')} />
      <FunctionField label="Price" render={record => `£${numeral(get(record, 'priceData.netAmountToPay')).format('0,0.00')}`} />
      <FunctionField label="Discount" render={record => `£${numeral(get(record, 'priceData.discount', 0)).format('0,0.00')}`} />
      <FunctionField label="Final price" render={record => `£${numeral(get(record, 'priceData.netAmountToPay') - get(record, 'priceData.discount',0)).format('0,0.00')}`} />
      <ReferenceField linkType={false} allowEmpty source="memberId" reference="members" sortable={false}>
        <FunctionField render={member => `${member.firstName} ${member.lastName}`} />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default ExternalBookingsList;
