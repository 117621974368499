import React from 'react';
import { Create, email, ReferenceInput, required, AutocompleteInput, SimpleForm, TextInput } from 'react-admin';
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const MembersCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="edit">
      <Button
        style={{ marginRight: 'auto', width: 120 }}
        variant="contained"
        size="small"
        color="primary"
        onClick={() => props.history.push('/members')}
      >
        {'< Go back'}
      </Button>
      <TextInput source="email" validate={[required(), email()]} />
      <TextInput source="firstName" validate={[required()]} />
      <TextInput source="lastName" validate={[required()]} />
      <TextInput source="jobTitle" />
      <ReferenceInput label="Workspace" source="spaceId" reference="spaces" validate={[required()]}>
        <AutocompleteInput optionText={choice => choice.name} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default withRouter(MembersCreate);
