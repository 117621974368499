import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { Create, FileField, FileInput, SimpleForm, ReferenceInput, AutocompleteInput } from 'react-admin';

const CardsImportCreate = props => {
  return (
    <Create {...props} title="Cards Import">
      <SimpleForm redirect="/cards">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial #</TableCell>
              <TableCell>Token</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>...</TableCell>
              <TableCell>...</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>The table above presents the CSV structure</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <FileInput source="csv" label="CSV"
                   accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
          <FileField source="csv" title="title"/>
        </FileInput>
        <ReferenceInput label="Workspace" source="spaceId" name="Workspace" reference="spaces">
          <AutocompleteInput optionText={choice => choice.name}/>
        </ReferenceInput>

      </SimpleForm>
    </Create>
  );
};

export default CardsImportCreate;
