import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  title: {
    marginBottom: 20,
    fontSize: 30,
  },
};

export default (listingTitle = '') => ListingComponent => {
  class ComposedComponent extends React.Component {
    render() {
      return (
        <>
          <Typography
            variant="title"
            color="inherit"
            id={listingTitle || 'react-admin-title'}
            className={this.props.classes.title}
          >
            {listingTitle}
          </Typography>
          <ListingComponent {...this.props} />
        </>
      );
    }
  }
  return withStyles(styles)(ComposedComponent);
};
