import React from 'react';
import { Datagrid, List, TextField, CardActions, CreateButton, ExportButton } from 'react-admin';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import BulkExportButton from '../_common/components/BulkExportButton';

const styles = {
  '@global': {
    'th:first-of-type': {
      width: '90px',
    },
  },
};

const FaqsActionButtons = props => (
  <>
    <BulkExportButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </>
);

const FaqsCategoriesListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  push,
}) => (
  <CardActions style={{ width: '100%' }}>
    <Button style={{ marginRight: 'auto' }} size="small" color="primary" onClick={() => push('/support/faqs')}>
      {'< Back to FAQs'}
    </Button>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </CardActions>
);

const FaqCategories = props => (
  <List
    {...props}
    bulkActionButtons={<FaqsActionButtons />}
    actions={<FaqsCategoriesListActions push={props.history.push} />}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
    </Datagrid>
  </List>
);
export default withStyles(styles)(FaqCategories);
