import get from 'lodash/get';

const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (!file.rawFile) {
      return resolve(file.src);
    }

    reader.readAsDataURL(file.rawFile);

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
  });

const convertCsvToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    if (!file.rawFile) {
      resolve(file.src);
    }

    reader.readAsDataURL(file.rawFile);

    reader.onload = () => {
      let encoded = reader.result.replace(/^data:(.*;base64,)?/, '');
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };

    reader.onerror = reject;
  });

const addUploadFeature = requestHandler => async (type, resource, params) => {
  if (
    (type === 'CREATE' && resource === 'imports') ||
    ((type === 'UPDATE' || type === 'CREATE') && resource === 'resources') ||
    ((type === 'UPDATE' || type === 'CREATE') && resource === 'companies') ||
    (type === 'CREATE' && resource === 'membersImports') ||
    (type === 'CREATE' && resource === 'cardsImports') ||
    ((type === 'UPDATE' || type === 'CREATE') && resource === 'addons') ||
    ((type === 'UPDATE' || type === 'CREATE') && resource === 'documents') ||
    ((type === 'UPDATE' || type === 'CREATE') && resource === 'alerts')
  ) {
    if (params.data.csv) {
      const csv = await convertCsvToBase64(params.data.csv);
      return requestHandler(type, resource, {
        ...params,
        data: {
          ...params.data,
          csv,
        },
      });
    }
    if (params.data.photos) {
      const photos = [];
      for (const photo of params.data.photos) {
        if (photo) {
          photos.push(await convertFileToBase64(photo));
        }
      }
      return requestHandler(type, resource, {
        ...params,
        data: {
          ...params.data,
          photos,
        },
      });
    }
    if (params.data.photo) {
      const photo = await convertFileToBase64(params.data.photo);
      return requestHandler(type, resource, {
        ...params,
        data: {
          ...params.data,
          photo,
        },
      });
    }
    if (params.data.document) {
      const src = await convertFileToBase64(params.data.document);
      return requestHandler(type, resource, {
        ...params,
        data: {
          ...params.data,
          document: {
            src,
            title: params.data.document.title,
          },
        },
      });
    }
  }

  if (get(params, 'data')) {
    let privateKeyString = params.data.privateKeyString;
    if (params.data.privateKey) {
      privateKeyString = await convertFileToBase64(params.data.privateKey);
    }
    return requestHandler(type, resource, {
      ...params,
      data: {
        ...params.data,
        privateKeyString,
      },
    });
  }
  return requestHandler(type, resource, params);
};

export default addUploadFeature;
