import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

const dataSet = ({ labels, data }) => ({
  data: {
    labels,
    datasets: [
      {
        data,
        backgroundColor: 'rgb(37, 179, 176)',
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      xAxes: [
        {
          gridLines: { display: false },
        },
      ],
      yAxes: [
        {
          gridLines: { display: false },
          ticks: {
            beginAtZero: true,
            stepSize: 1,
          },
        },
      ],
    },
  },
});

const UserActivityBarChart = ({ labels = [], data = [], options = {} }) => <Line height={1000} {...dataSet({ labels, data })} />;

UserActivityBarChart.propTypes = {
  labels: PropTypes.array,
  data: PropTypes.array,
};

UserActivityBarChart.defaultProps = {
  labels: [],
  data: [],
};

export default UserActivityBarChart;
