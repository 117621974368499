import React from 'react';
import { Edit } from 'react-admin';
import AddonsForm from './AddonsForm';

const AddonsEdit = props => (
  <Edit {...props} undoable={false}>
    <AddonsForm redirect={false} />
  </Edit>
);

export default AddonsEdit;
