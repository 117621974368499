import React from 'react';
import { Create } from 'react-admin';
import BookingsForm from './BookingsForm';

const BookingsCreate = props => (
  <Create {...props}>
    <BookingsForm {...props} redirect="edit" />
  </Create>
);

export default BookingsCreate;
