import React from 'react';
import { Create, ReferenceInput, required, AutocompleteInput, SimpleForm } from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const InvoicesCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <h3>Caution</h3>
      <Button
        style={{ marginRight: 'auto', width: 120 }}
        variant="contained"
        size="small"
        color="primary"
        onClick={() => props.history.push('/invoices')}
      >
        {'< Go back'}
      </Button>
      <ReferenceInput label="Business" source="spaceId" reference="spaces" validate={[required()]}>
        <AutocompleteInput optionText={choice => choice.description} />
      </ReferenceInput>
      <DateInput
            source="from"
            label="Invoice from"
            validate={[required()]}
            options={{ clearable: true, ampm: false, format: 'do MMMM YYYY' }}
      />
      <DateInput
            source="to"
            label="Invoice to"
            validate={[required()]}
            options={{ clearable: true, ampm: false, format: 'do MMMM YYYY' }}
      />
    </SimpleForm>
  </Create>
);

export default withRouter(InvoicesCreate);
