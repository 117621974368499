import React from 'react';
import { Edit } from 'react-admin';
import AddonForm from './AddonAssignmentsForm';

const AddonAssignmentsEdit = props => (
  <Edit {...props} undoable={false}>
    <AddonForm redirect={false} />
  </Edit>
);

export default AddonAssignmentsEdit;
