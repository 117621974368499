import React from 'react';
import { AutocompleteArrayInput, ReferenceArrayInput, required, SimpleForm, TextInput } from 'react-admin';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

const CompaniesForm = ({ formValues, ...props }) => (
  <SimpleForm {...props}>
    <ReferenceArrayInput label="Workspaces" source="spaceIds" reference="spaces" validate={[required()]}>
      <AutocompleteArrayInput optionText="name" />
    </ReferenceArrayInput>
    <TextInput source="name" label="Legal Entity Name" validate={[required()]} />
    <TextInput source="address" validate={[required()]} />
    <TextInput source="vat" label="VAT ID" validate={[required()]} />
    {/* <TextInput source="primaryEmail" validate={[email()]} />
    <TextInput source="billingEmail" validate={[email()]} />
    <TextInput source="subdomain" validate={[required()]} />
    <ImageInput source="photo" label="Logo" accept="image/*">
      <ImageField source="src" title="title" />
    </ImageInput>
    {!get(props.formValues, 'photo.src') && (
      <ImageField source="photo" label={null} />
    )} */}
  </SimpleForm>
);

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = connect(mapStateToProps);

export default enhance(CompaniesForm);
