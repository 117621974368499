import React, { useEffect } from 'react';
import { Edit, email, ReferenceInput, required, SelectInput, AutocompleteInput, SaveButton, SimpleForm, TextInput, Toolbar } from 'react-admin';
import { ROLES } from '../_app/constants';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { getFormValues } from 'redux-form';
import DeleteButtonWithConfirmation from "../layout/DeleteButtonWithConfirmation";
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const onKeyDown = e => {
  if (e.keyIdentifier === 'U+000A' || e.keyIdentifier === 'Enter' || e.keyCode === 13) {
    if (e.target.nodeName === 'INPUT' && e.target.type === 'text') {
      e.preventDefault();
      return false;
    }
  }
};
const MemberEditToolbar = withStyles(styles)(({ classes, ...props}) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props}/>
    <DeleteButtonWithConfirmation {...props} />
  </Toolbar>
));

const MembersEdit = ({ formValues, ...props }) => {
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown, true);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    }
  });
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm toolbar={<MemberEditToolbar/>} redirect={false}>
        <Button
          style={{ marginRight: 'auto', width: 120 }}
          variant="contained"
          size="small"
          color="primary"
          onClick={() => props.history.push('/members')}
        >
          {'< Go back'}
        </Button>
        <TextInput source="email" validate={[required(), email()]} />
        <TextInput source="firstName" validate={[required()]}/>
        <TextInput source="lastName" validate={[required()]}/>
        <SelectInput
          source="role"
          validate={[required()]}
          choices={ROLES}
        />
        <ReferenceInput label="Workspace" source="spaceId" reference="spaces" validate={[required()]}>
          <AutocompleteInput optionText={choice => choice.name}/>
        </ReferenceInput>
        <TextInput source="jobTitle" />
        <ReferenceInput filter={{ spaceId: formValues.spaceId, memberId: null }} source="cardId" reference="cards" label="Access Card Serial #">
          <AutocompleteInput optionText="serialNumber" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = compose(withRouter, connect(mapStateToProps));

export default enhance(MembersEdit);
