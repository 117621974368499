import React from 'react';
import { ReferenceField, Show, SimpleShowLayout, FunctionField, TextField } from 'react-admin';
import moment from 'moment';

const FlexSessionsShow = props => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="workLifeSpace" label="Work.Life Space" />
        <ReferenceField allowEmpty source="userId" reference="members" label="Email">
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField allowEmpty source="userId" reference="members" label="First name">
          <TextField source="firstName" />
        </ReferenceField>
        <ReferenceField allowEmpty source="userId" reference="members" label="Last name">
          <TextField source="lastName" />
        </ReferenceField>
        <FunctionField label="Check in" render={record => moment(record.checkIn).format('YYYY-MM-DD HH:mm')} />
        <FunctionField label="Check out" render={record => (record.checkOut ? moment(record.checkOut).format('YYYY-MM-DD HH:mm') : '-')} />
        <FunctionField label="Price" render={record => (record.price ? `£${record.price}` : '')} />
        <TextField source="rfid" label="Rfid" />
      </SimpleShowLayout>
    </Show>
  );
};

export default FlexSessionsShow;
