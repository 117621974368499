import React from 'react';
import { Create } from 'react-admin';
import CompaniesForm from './CompaniesForm';

const CompaniesCreate = props => (
  <Create {...props}>
    <CompaniesForm redirect="edit" />
  </Create>
);

export default CompaniesCreate;
