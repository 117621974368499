import React, { createElement } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import capitalize from 'lodash/capitalize';
import includes from 'lodash/includes';
import { MenuItemLink, getResources, refreshView as refreshViewAction } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import GlobalFilters from '../_common/components/GlobalFilters';
import { Divider, Tab, Tabs } from '@material-ui/core';

const styles = {
  sidebar: {
    width: 240,
    height: 'auto',
    position: 'relative',
    overflowX: 'hidden',
    transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  hiddenSidebar: {
    width: 60,
  },
  dividerWrapper: {
    margin: '16px 0px',
  },
  sectionTitle: {
    fontSize: 20,
    padding: '12px 16px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    transition: 'font-size 195ms',
  },
  sectionTitleClosed: {
    fontSize: 10,
    padding: '12px 5px',
  },
  menuItem: {
    padding: '6px 16px',
  },
  mb20: {
    marginBottom: '20px',
  },
};

const tabs = [
  { id: 'admin', name: 'admin' },
  { id: 'app', name: 'app' },
];

const MySidebar = ({ resources, sidebarOpen, refreshView, classes, onMenuClick, logout, ...props }) => {
  const administrationResources = resources.filter(resource => resource.icon && !resource.options.isAppManagement);
  const appManagementResources = resources.filter(resource => resource.icon && resource.options.isAppManagement);

  React.useEffect(() => {
    if (appManagementResources.find(({ name }) => includes(props.location.pathname, name))) {
      return setTabValue('app');
    }
  }, []);

  const [tabValue, setTabValue] = React.useState('admin');
  const handleChange = (event, value) => setTabValue(value);
  return (
    <div className={classnames(classes.sidebar, sidebarOpen && classes.hiddenSidebar)}>
      <GlobalFilters refreshView={refreshView} history={props.history} />

      {!sidebarOpen ? (
        <div>
          <Tabs value={tabValue} orientation="vertical" indicatorColor="primary" onChange={handleChange}>
            {tabs.map(choice => (
              <Tab style={{ minWidth: 120 }} key={choice.id} label={choice.name} value={choice.id} />
            ))}
          </Tabs>
          <div className={classes.mb20} />
          {tabValue === 'admin' && (
            <div>
              {administrationResources.map(resource => (
                <MenuItemLink
                  key={resource.name}
                  to={`/${resource.name}`}
                  primaryText={
                    (resource.options && resource.options.label) ||
                    resource.name
                      .split('_')
                      .map(capitalize)
                      .join(' ')
                  }
                  leftIcon={createElement(resource.icon)}
                  onClick={onMenuClick}
                  className={classes.menuItem}
                />
              ))}
            </div>
          )}
          {tabValue === 'app' && (
            <div>
              {appManagementResources.map(resource => (
                <MenuItemLink
                  key={resource.name}
                  to={`/${resource.name}`}
                  primaryText={(resource.options && resource.options.label) || resource.name}
                  leftIcon={createElement(resource.icon)}
                  onClick={onMenuClick}
                  className={classes.menuItem}
                />
              ))}
            </div>
          )}
        </div>
      ) : (
        <div>
          <Typography className={classnames(classes.sectionTitle, sidebarOpen && classes.sectionTitleClosed)}>
            Administration
          </Typography>
          {administrationResources.map(resource => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && resource.options.label) ||
                resource.name
                  .split('_')
                  .map(capitalize)
                  .join(' ')
              }
              leftIcon={createElement(resource.icon)}
              onClick={onMenuClick}
              className={classes.menuItem}
            />
          ))}
          <div className={classes.dividerWrapper}>
            <Divider />
          </div>
          <Typography className={classnames(classes.sectionTitle, sidebarOpen && classes.sectionTitleClosed)}>
            App Management
          </Typography>
          {appManagementResources.map(resource => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={(resource.options && resource.options.label) || resource.name}
              leftIcon={createElement(resource.icon)}
              onClick={onMenuClick}
              className={classes.menuItem}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  resources: getResources(state),
  sidebarOpen: state.admin.ui.sidebarOpen,
});

const enhance = compose(withStyles(styles), withRouter, connect(mapStateToProps, { refreshView: refreshViewAction }));

export default enhance(MySidebar);
