import React from 'react';
import { DateInput, ReferenceInput, AutocompleteInput, List, Datagrid, FunctionField, ReferenceField, TextField, Filter, TextInput } from 'react-admin';
import moment from 'moment';
import BulkExportButton from '../_common/components/BulkExportButton';

const checkInDuration = (d1, d2) => {
  if (!d2) {
    return '-';
  }
  let start = moment(d1);
  let end = moment(d2);
  let diff = end.diff(start);
  return moment.utc(diff).format('HH:mm:ss');
};


const FlexSessionsActionButtons = props => (
  <>
    <BulkExportButton {...props} />
  </>
);

const FlexSessionsFilters = props => (
  <Filter {...props}>
    <TextInput source="workLifeSpace" label="Work.Life Space" alwaysOn/>
    {/* <ReferenceInput label="Workspace" source="spaceId" reference="spaces" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput> */}
    <DateInput source="checkIn_gte" alwaysOn label="Check-In" options={{
			mode: "portrait",
			locale: "nl"
		}} />
    <DateInput source="checkIn_lte" alwaysOn label="Check-In" options={{
			mode: "portrait",
			locale: "nl"
		}} />

    <TextInput source="email" label="Email" />
    <TextInput source="firstName" label="First name" />
    <TextInput source="lastName" label="Last name" />
  </Filter>
);

const FlexSessionsList = props => (
  <List {...props} bulkActionButtons={<FlexSessionsActionButtons />} filters={<FlexSessionsFilters />}>
    <Datagrid rowClick="show">
      <TextField source="workLifeSpace" label="Work.Life Space" />
      <ReferenceField linkType={false} allowEmpty label="Workspace" source="spaceId" reference="spaces" sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField linkType={false} allowEmpty label="Email" source="userId" reference="members" sortable={false}>
        <TextField source="email" />
      </ReferenceField>
      <FunctionField source="checkIn" label="Check-In" render={record => moment(record.checkIn).format('YYYY-MM-DD HH:mm')} />
      <FunctionField
        source="checkOut"
        label="Check-Out"
        render={record => (record.checkOut ? moment(record.checkOut).format('YYYY-MM-DD HH:mm') : '-')}
      />
      <FunctionField label="Duration" render={record => checkInDuration(record.checkIn, record.checkOut)} />
      <FunctionField label="Price" render={record => (record.price ? `£${record.price}` : '')} />
    </Datagrid>
  </List>
);

export default FlexSessionsList;
