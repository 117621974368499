import React from 'react';
import get from 'lodash/get';
import {
  BooleanInput,
  TextInput,
  AutocompleteInput,
  LongTextInput,
  TextField,
  NumberInput,
  FunctionField,
  SelectInput,
  SimpleForm,
  required,
  Query,
  Toolbar,
  ReferenceInput,
  SaveButton,
} from 'react-admin';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { DateInput } from 'react-admin-date-inputs';
import { ADDON_STATUSES_CHOICES, ADDON_RECURRENCE_RULES } from '../_app/constants';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Button } from '@material-ui/core';
import { change } from 'redux-form';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const AddonEditToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props} />
  </Toolbar>
));

export const ADDON_STATUSES = {
  ORDERED: 'ordered',
  PROCESSED: 'processed',
  PENDING: 'pending',
  DELIVERED: 'delivered',
};

const XeroConfig = ({ spaceId, key }) => (
  <Query
    type="GET_ONE"
    resource="spaces"
    payload={{
      id: spaceId,
    }}
  >
    {({ data, loading, error }) => {
      if (loading) {
        return null;
      }
      return (
        <ReferenceInput
          style={{ width: 256 }}
          validate={[required()]}
          source="xeroAccountCode"
          reference="xero/accounts"
          label="Xero Account Code"
          filter={{ xeroConfigId: (data || {}).xeroConfigId }}
        >
          <SelectInput optionText="Name" />
        </ReferenceInput>
      );
    }}
  </Query>
);

class AddonAssignmentsForm extends React.Component {
  state = { keys: [Math.random(), Math.random()] };
  setKeys = () => this.setState({ keys: [Math.random(), Math.random()] });

  componentDidUpdate(prevProps) {
    const { formChange, formValues } = this.props;
    const { formValues: prevFormValues } = prevProps;

    if (formValues.spaceId !== prevFormValues.spaceId && prevFormValues.spaceId) {
      this.setKeys([Math.random(), Math.random()]);
      formChange('record-form', 'userId', null);
      formChange('record-form', 'xeroAccountCode', null);
    }
    if (formValues.isBespoke !== prevFormValues.isBespoke) {
      if (typeof formValues.isBespoke === 'boolean' && formValues.isBespoke) {
        formChange('record-form', 'addonId', null);
      } else {
        formChange('record-form', 'extraName', null);
      }
    }
  }

  filteredChoices = () => {
    const isEdit = !!get(this.props, 'match.params.id');
    console.log('isEDIT: ', isEdit);
    return ADDON_STATUSES_CHOICES.filter((choice) => {
      if (!isEdit) {
        return choice.id !== 'cancelled' && choice.id !== 'delivered';
      }
      return true;
    });
  };

  render() {
    const { formValues } = this.props;
    const { keys } = this.state;
    return (
      <SimpleForm redirect="edit" {...this.props} toolbar={<AddonEditToolbar />}>
        <Button
          style={{ marginRight: 'auto', width: 120 }}
          variant="contained"
          size="small"
          color="primary"
          onClick={() => this.props.history.push('/addon_assignments')}
        >
          {'< Go back'}
        </Button>
        <BooleanInput label="Manual" source="isBespoke" defaultValue={false} />
        {formValues.isBespoke ? (
          <TextInput source="extraName" label="Extra name" validate={[required()]} />
        ) : (
          <ReferenceInput
            label="Extra name"
            source="addonId"
            reference="addons"
            validate={[required()]}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        )}
        <ReferenceInput source="spaceId" reference="spaces" label="Business" validate={[required()]}>
          <AutocompleteInput optionText={(choice) => `${choice.description}`} />
        </ReferenceInput>
        <NumberInput source="cost" label="Price (in £)" step=".01" />
        {formValues.spaceId && <XeroConfig key={keys[1]} spaceId={formValues.spaceId} />}
        {formValues.spaceId && (
          <ReferenceInput
            key={keys[0]}
            source="userId"
            reference="members"
            label="Member"
            filter={{ spaceId: formValues.spaceId }}
            allowEmpty
          >
            <AutocompleteInput optionText={(choice) => `${choice.firstName} ${choice.lastName}`} />
          </ReferenceInput>
        )}
        <SelectInput source="status" validate={[required()]} choices={this.filteredChoices()} />
        <DateInput
          source="startsAt"
          label="First delivery"
          options={{ clearable: true, ampm: false, format: 'do MMMM YYYY' }}
          validate={[required()]}
        />
        <SelectInput
          source="recurrenceRule"
          label="Frequency"
          defaultValue="oneOff"
          validate={[required()]}
          choices={[
            { id: 'oneOff', name: 'One-off' },
            ...Object.keys(ADDON_RECURRENCE_RULES).map((key, idx) => ({
              id: key,
              name: ADDON_RECURRENCE_RULES[key],
            })),
          ]}
        />
        <LongTextInput source="wecComments" label="WEC Comments" rows={4} />
        {formValues.createdAt && (
          <FunctionField label="Order date" render={(record) => moment(record.createdAt).format('YYYY-MM-DD HH:mm')} />
        )}
        {formValues.cancelledAt && (
          <FunctionField
            label="Cancellation date"
            render={(record) => moment(record.cancelledAt).format('YYYY-MM-DD')}
          />
        )}
        {formValues.additionalInfo && <TextField source="additionalInfo" label="Requester comments" />}
      </SimpleForm>
    );
  }
}

const mapStateToProps = (state) => ({
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = compose(withRouter, connect(mapStateToProps, { formChange: change }));

export default enhance(AddonAssignmentsForm);
