import React from 'react';
import { Edit, SimpleForm, TextField, ReferenceField, SelectInput, FunctionField} from 'react-admin';
import moment from 'moment';
export const InvoiceEdit = props => (
  <Edit {...props}>
      <SimpleForm redirect={false}>
      <TextField source="InvoiceNumber" />
        <ReferenceField allowEmpty source="spaceId" reference="spaces" label="Workspace">
          <TextField source="name" />
        </ReferenceField>
        <SelectInput
          source="status"
          choices={[
            {id: 'paid', name: 'paid'},
            {id: 'pending', name: 'pending'},
            {id: 'voided', name: 'voided'},
          ]}
        />
                <SelectInput
          source="approvalStatus"
          choices={[
            {id: 'pending', name: 'pending'},
            {id: 'approved', name: 'approved'},
          ]}
        />

        <FunctionField label="Amount Due" render={record => (`${record.CurrencyCode} ${record.AmountDue}`)} />
        <FunctionField label="Amount Paid" render={record => (`${record.CurrencyCode} ${record.AmountPaid}`)} />
        <FunctionField label="Date" render={record => moment(record.DateString).format('YYYY-MM-DD')} />
        <FunctionField label="Due Date" render={record => moment(record.DueDateString).format('YYYY-MM-DD')} />
        <FunctionField label="PDF" render={record => (<a href={record.invoiceUrl} target='_blank' rel="noopener noreferrer" >View PDF</a>)} />
      </SimpleForm>
  </Edit>
);

export default InvoiceEdit;
