import React from 'react';
import { Datagrid, List, TextField, ReferenceArrayField, ChipField, SingleFieldList } from 'react-admin';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  '@global': {
    'th:first-of-type': {
      width: '90px',
    },
  },
};

const MemberGroupsActionButtons = props => <DeleteButtonWithConfirmation {...props} />;

const MemberGroupsCategories = props => (
  <List {...props} bulkActionButtons={<MemberGroupsActionButtons />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ReferenceArrayField sortable={false} label="Members" source="memberIds" reference="members">
        <SingleFieldList linkType={false}>
          <ChipField source="email" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </List>
);
export default withStyles(styles)(MemberGroupsCategories);
