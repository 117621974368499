import React from 'react';
import { Edit } from 'react-admin';
import XeroConfigsForm from './XeroConfigsForm';

const XeroConfigsEdit = props => (
  <Edit {...props} undoable={false}>
    <XeroConfigsForm redirect={false} />
  </Edit>
);

export default XeroConfigsEdit;
