import React, { Fragment } from 'react';
import moment from 'moment';
import { FunctionField, Datagrid, AutocompleteInput, Filter, List, ReferenceInput, ReferenceField, TextField, TextInput } from 'react-admin';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import BulkExportButton from '../_common/components/BulkExportButton';

const DocumentsActionButtons = props => (
  <Fragment>
    <BulkExportButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </Fragment>
);

const DocumentsFilters = props => (
  <Filter {...props}>
    <TextInput source="name" />
    {/* <ReferenceInput label="Workspace" source="spaceId" reference="spaces">
      <AutocompleteInput optionText="name" />
    </ReferenceInput> */}
    {/* <ReferenceInput label="User" source="userId" reference="members">
      <AutocompleteInput optionText={({ firstName, lastName }) => `${firstName} ${lastName}`} />
    </ReferenceInput> */}
    <TextInput source="title" label="Document name" />
  </Filter>
);

const ResourceList = props => (
  <List {...props} filters={<DocumentsFilters />} bulkActionButtons={<DocumentsActionButtons />}>
    <Datagrid rowClick="edit">
      <TextField source="name" sortable={false} />
      <FunctionField sortBy="createdAt" label="Created at" render={record => moment(record.createdAt).format('DD.MM.YYYY HH:mm')} />
      <TextField source="document.title" label="Document title" sortable={false} />
      <ReferenceField linkType={false} allowEmpty source="spaceId" reference="spaces" label="Workspace" sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      {/* <ReferenceField allowEmpty source="userId" reference="members" sortable={false}>
        <TextField source="email" />
      </ReferenceField> */}
    </Datagrid>
  </List>
);
export default ResourceList;
