import React from 'react';
import { Edit } from 'react-admin';
import DocumentsForm from './DocumentsForm';

const DocumentsEdit = props => (
  <Edit {...props} undoable={false}>
    <DocumentsForm redirect={false} />
  </Edit>
);

export default DocumentsEdit;
