import React from 'react';
import { Create } from 'react-admin';
import SpacesForm from './SpacesForm';

const SpaceCreate = props => (
  <Create {...props}>
    <SpacesForm redirect="edit" />
  </Create>
);

export default SpaceCreate;
