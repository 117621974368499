import React from 'react';
import { required, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';


const CardsForm = props => (
  <SimpleForm {...props}>
    <Button
      style={{ marginRight: 'auto', width: 120 }}
      variant="contained"
      size="small"
      color="primary"
      onClick={() => props.history.push('/cards')}
    >
      {'< Go back'}
    </Button>
    <TextInput source="serialNumber" validate={[required()]} />
    <TextInput source="token" validate={[required()]} />
    <ReferenceInput label="Workspace" source="spaceId" reference="spaces" validate={[required()]}>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </SimpleForm>
);

export default withRouter(CardsForm);
