import React from 'react';
import { List, ListItem, Divider, Button, Typography, CircularProgress } from '@material-ui/core';
import { Query } from 'react-admin';
import uniqBy from 'lodash/uniqBy';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

const ExtrasOverview = ({ spaceId, history: { push } }) => {
  return (
    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginBottom: 80 }}>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <Typography style={{ fontSize: 24 }}>Extras overview</Typography>
        <Button onClick={() => push('/addon_assignments')} variant="contained">
          Edit extras
        </Button>
      </div>
      <Query
        type="GET_MANY"
        resource="addon_assignments"
        payload={{
          filters: {
            spaceId,
          },
          limit: 5,
        }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <CircularProgress style={{ margin: '10px auto' }} />;
          }
          return (
            <Query
              type="GET_MANY"
              resource="addons"
              payload={{
                ids: uniqBy(data, 'addonId').map(assignment => assignment.addonId),
              }}
            >
              {({ data: addons, loading: loadingAddons, ...rest }) => {
                if (loading || loadingAddons) {
                  return <CircularProgress style={{ margin: '10px auto' }} />;
                }
                return (
                  <List component="nav">
                    {isEmpty(data) && !loading && (
                      <div>The list is empty</div>
                    )}
                    {data.map(item => (
                      <>
                        <ListItem button onClick={() => push(`/addon_assignments/${item._id}`)}>
                          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <span>{((addons || []).find(addon => addon._id === item.addonId) || {}).name}</span>
                            <span>{item.status}</span>
                          </div>
                        </ListItem>
                        <Divider />
                      </>
                    ))}
                  </List>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </div>
  );
};

export default withRouter(ExtrasOverview);
