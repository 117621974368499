import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, crudUpdateMany } from 'react-admin';
import MailIcon from '@material-ui/icons/Mail'

class  MembersInviteButton extends Component {
  state = {
    isOpen: false,
  }

  handleClick = () => {
      this.setState({ isOpen: true });
  }

  handleDialogClose = () => {
      this.setState({ isOpen: false });
  };

  handleConfirm = () => {
      const { basePath, crudUpdateMany, resource, selectedIds } = this.props;
      crudUpdateMany(resource, selectedIds, { views: 0 }, basePath);
      this.setState({ isOpen: true });
  };

  render() {
      return (
          <Fragment>
              <Button label="Send invitations" onClick={this.handleClick}>
                  <MailIcon />
              </Button>
              <Confirm
                  isOpen={this.state.isOpen}
                  title="Invite members"
                  content="Click confirm to send invitations to selected members?"
                  onConfirm={this.handleConfirm}
                  onClose={this.handleDialogClose}
              />
          </Fragment>
      );
  }
}

export default connect(undefined, { crudUpdateMany })(MembersInviteButton);
