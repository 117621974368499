import React from 'react';
import { Edit } from 'react-admin';
import FaqsForm from './FaqsForm';

const FaqsEdit = props => (
  <Edit {...props} undoable={false}>
    <FaqsForm redirect={false} />
  </Edit>
);

export default FaqsEdit;
