import React from 'react';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { TextInput, FileInput, FileField, ReferenceInput, AutocompleteInput, SimpleForm, required, Toolbar, SaveButton } from 'react-admin';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Button } from '@material-ui/core';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const DocumentsEditToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </Toolbar>
));

const DocumentsForm = ({ formValues, ...props }) => (
  <SimpleForm {...props} toolbar={<DocumentsEditToolbar />}>
    <Button
      style={{ marginRight: 'auto', width: 120 }}
      variant="contained"
      size="small"
      color="primary"
      onClick={() => props.history.push('/documents')}
    >
      {'< Go back'}
    </Button>
    <TextInput source="name" validate={[required()]} />
    <ReferenceInput allowEmpty label="Workspace" source="spaceId" reference="spaces">
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    {/* {get(formValues, 'spaceId') && (
      <ReferenceInput label="User" source="userId" reference="members" filter={{ spaceId: get(formValues, 'spaceId') }} allowEmpty>
        <CustomAutocomplete
          optionText={({ firstName, lastName }) => `${firstName} ${lastName}`}
        />
      </ReferenceInput>
    )} */}
    <FileInput source="document" label="Document" accept="application/*" validate={[required()]}>
      <FileField
        source="src"
        title="title"
        onClick={e => {
          e.preventDefault();
          const downloadLink = document.createElement('a');
          downloadLink.href = formValues.document.src;
          downloadLink.download = formValues.document.title;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }}
      />
    </FileInput>
  </SimpleForm>
);

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
)

export default enhance(DocumentsForm);
