import React from 'react';
import { Create } from 'react-admin';
import AddonsForm from './AddonsForm';

const AddonsCreate = props => (
  <Create {...props}>
    <AddonsForm redirect="edit" />
  </Create>
);

export default AddonsCreate;
