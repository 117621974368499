import React from 'react';
import {
  Filter,
  ReferenceInput,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
  AutocompleteInput,
  Datagrid,
  CardActions,
  CreateButton,
  ExportButton,
  List,
  TextField,
  ReferenceField,
} from 'react-admin';
import Button from '@material-ui/core/Button';

import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import { withStyles } from '@material-ui/core/styles';
import BulkExportButton from '../_common/components/BulkExportButton';
import { pretifyAndShortenColleciton } from '../utils';

const styles = {
  '@global': {
    'th:first-of-type': {
      width: '90px',
    },
  },
};

const FaqsFilters = props => (
  <Filter {...props}>
    {/* <ReferenceInput label="Workspace" source="spaceId" reference="spaces" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput> */}
    <ReferenceInput label="Category" source="categoryId" reference="support/faq_categories" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const FaqsActionButtons = props => (
  <>
    <BulkExportButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </>
);

const FaqsListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  push,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <Button size="small" color="primary" onClick={() => push('/support/faq_categories')}>
      Create/remove categories
    </Button>
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </CardActions>
);

const Businesses = ({ data }) => <div>{pretifyAndShortenColleciton(data, ['name'], 2, 'All')}</div>;

const FaqsList = props => (
  <List
    {...props}
    bulkActionButtons={<FaqsActionButtons />}
    filters={<FaqsFilters />}
    actions={<FaqsListActions push={props.history.push} />}
  >
    <Datagrid rowClick="edit">
      <TextField source="q" label="Question" sortable={false} />
      <ReferenceField
        linkType={false}
        allowEmpty
        label="Category"
        sortable={false}
        source="categoryId"
        reference="support/faq_categories"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField label="Business" source="spaceIds" reference="spaces">
        <Businesses />
      </ReferenceArrayField>
    </Datagrid>
  </List>
);
export default withStyles(styles)(FaqsList);
