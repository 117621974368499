import React from 'react';
import { Show, SimpleShowLayout, FunctionField, ReferenceField, TextField } from 'react-admin';
import moment from 'moment';



const InvoicesShow = props => {


  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="InvoiceNumber" />
        <ReferenceField allowEmpty source="spaceId" reference="spaces" label="Workspace">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="status" />
        <TextField source="approvalStatus" />
        <TextField source="emailStatus"/>
        <TextField source="chargeStatus"/>
        <FunctionField label="Amount Due" render={record => (`${record.CurrencyCode} ${record.AmountDue}`)} />
        <FunctionField label="Amount Paid" render={record => (`${record.CurrencyCode} ${record.AmountPaid}`)} />
        <FunctionField label="Date" render={record => moment(record.DateString).format('YYYY-MM-DD')} />
        <FunctionField label="Due Date" render={record => moment(record.DueDateString).format('YYYY-MM-DD')} />
        <FunctionField label="PDF" render={record => (<a href={record.invoiceUrl} target='_blank' rel="noopener noreferrer" >View PDF</a>)} />
      </SimpleShowLayout>
    </Show>
  );
};


export default InvoicesShow;
