import React from 'react';
import { Create } from 'react-admin';
import AddonForm from './AddonAssignmentsForm';

const AddonAssignmentsCreate = (props) => (
  <Create {...props} undoable={false}>
    <AddonForm redirect={false} />
  </Create>
);

export default AddonAssignmentsCreate;
