export const ROLES = [
  { id: 'WL_ADMIN', name: 'WL_ADMIN' },
  { id: 'SUPER_ADMIN', name: 'SUPER_ADMIN' },
  { id: 'SECONDARY_ADMIN', name: 'SECONDARY_ADMIN' },
  { id: 'MEMBER', name: 'MEMBER' },
];

export const RECURRENCE_RULE_MAP = {
  everyDay: 'Every day',
  everyWeek: 'Every week',
  everyMonth: 'Every month',
};

export const ADDON_STATUSES_CHOICES = [
  { id: 'ordered', name: 'Ordered' },
  { id: 'processed', name: 'Processed' },
  { id: 'cancelled', name: 'Cancelled' },
  { id: 'delivered', name: 'Delivered', disabled: true },
];

export const TZ = 'Europe/London';

export const ADDON_RECURRENCE_RULES = {
  daily: 'Daily',
  weekly: 'Weekly',
  every2weeks: 'Fortnightly',
  monthly: 'Monthly',
};

export const DEFAULT_SPACE_TOKEN_KEY = 'durotar-default-space';
