import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import { GET_LIST, GET_ONE, CREATE, UPDATE, UPDATE_MANY, DELETE_MANY, DELETE, GET_MANY, HttpError } from 'react-admin';
import API from './_app/api';

export default async (type, resource, params) => {
  let url = '';
  let query = {};
  let method = 'get';

  if (resource === 'cardsImports' && type === CREATE) {
    url = `/cards/import`;
    method = 'post';
  } else if (resource === 'membersImports' && type === CREATE) {
    url = `/members/import`;
    method = 'post';
  } else if (resource === 'members' && type === UPDATE_MANY) {
    url = `/members/resend_invitations`;
    method = 'post';
    params.data = { ids: filter(params.ids, id => !isEmpty(id)) };
  } else {
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { q: phrase, ...filters } = params.filter;
        query = omitBy(
          {
            limit: perPage,
            page,
            sort: params.sort.field,
            order: params.sort.order,
            filters: { phrase, ...filters },
            ids: filter(params.ids, id => !isEmpty(id)),
          },
          isUndefined,
        );
        url = `/${resource}`;
        break;
      }
      case GET_ONE:
        url = `/${resource}/${params.id}`;
        break;
      case CREATE:
        url = `/${resource}`;
        method = 'post';
        break;
      case UPDATE:
        url = `/${resource}/${params.id}`;
        method = 'put';
        break;
      case UPDATE_MANY:
        url = `/${resource}`;
        method = 'put';
        break;
      case DELETE:
        url = `/${resource}/${params.id}`;
        method = 'delete';
        break;
      case DELETE_MANY:
        url = `/${resource}`;
        method = 'delete';
        break;
      case GET_MANY:
        query = { ...params, ids: filter(params.ids, id => !isEmpty(id)) };
        url = `/${resource}`;
        break;
      default:
        throw new Error(`Unsupported Data Provider request type ${type}`);
    }
  }
  try {
    const { data } = await API({
      url,
      method,
      params: {
        ...query,
      },
      data: {
        ...get(params, 'data', {}),
      },
    });
    return convertHTTPResponse({ id: params.id, ...data }, type);
  } catch (error) {
    throw new HttpError(error.response.data.message, error.response.status, error.response.data.errors);
  }
};

function convertHTTPResponse(response, type) {
  switch (type) {
    case GET_LIST:
      return {
        data: response.data.map(item => ({
          ...item,
          id: item._id || item.ID,
        })),
        total: response.total,
      };
    case GET_MANY:
      return {
        data: response.data.map(item => ({
          ...item,
          id: item._id || item.ID,
        })),
      };
    case GET_ONE:
      return {
        data: {
          ...response.data,
          id: response.data._id,
          ...(response.data.photos ? { photos: response.data.photos.map(photo => ({ src: photo })) } : {}),
          ...(response.data.imageUrl ? { photo: { src: response.data.imageUrl } } : {}),
        },
      };
    case CREATE:
      return { data: { ...response.data, id: get(response, 'data._id') } };
    case UPDATE:
      return {
        data: {
          ...response.data,
          id: response.data._id,
          ...(response.data.photos ? { photos: response.data.photos.map(photo => ({ src: photo })) } : {}),
          ...(response.data.imageUrl ? { photo: { src: response.data.imageUrl } } : {}),
        },
      };
    case DELETE:
      return { data: { ...response } };
    default:
      return { data: response.data };
  }
}
