import React from 'react';
import { Edit } from 'react-admin';
import ResourcesForm from './ResourcesForm';

const ResourcesEdit = props => (
  <Edit {...props} undoable={false}>
    <ResourcesForm redirect={false} />
  </Edit>
);

export default ResourcesEdit;
