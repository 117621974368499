import React, { Fragment } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { SelectInput, DateInput, Filter, ReferenceField, Datagrid, FunctionField, List, TextField } from 'react-admin';
import { Divider, Tab, Tabs } from '@material-ui/core';
import numeral from 'numeral';
import { ADDON_RECURRENCE_RULES } from '../_app/constants';
import BulkExportButton from '../_common/components/BulkExportButton';
import { addonNextDelivery } from '../utils';

const AddonsActionButtons = (props) => <BulkExportButton {...props} />;

const AddonAssignmentsFilters = (props) => (
  <Filter {...props}>
    <DateInput source="createdAt" label="Order date" />
    <DateInput source="startsAt" label="First delivery" />
    <SelectInput
      label="Frequency"
      source="recurrenceRule"
      choices={[
        { id: 'oneOff', name: 'One-off' },
        ...Object.keys(ADDON_RECURRENCE_RULES).map((key, idx) => ({
          id: key,
          name: ADDON_RECURRENCE_RULES[key],
        })),
      ]}
    />
  </Filter>
);

const Row = (props) => (
  <Datagrid rowClick="edit" {...props}>
    <FunctionField
      label="Title"
      render={(record) => record.extraName || get(record, 'addon.name', '-')}
    />
    <FunctionField sortBy="isBespoke" label="Is bespoke?" render={(record) => (record.isBespoke ? 'Yes' : 'No')} />
    <ReferenceField linkType={false} sortable={false} label="Business" source="spaceId" reference="spaces">
      <TextField source="description" />
    </ReferenceField>
    <FunctionField
      label="Frequency"
      render={(record) =>
        ADDON_RECURRENCE_RULES[record.recurrenceRule] ? ADDON_RECURRENCE_RULES[record.recurrenceRule] : 'One-off'
      }
    />
    <FunctionField
      label="First delivery"
      sortBy="startsAt"
      render={record => (record.startsAt ? moment(record.startsAt).format('DD/MM/YYYY') : '-')}
    />
    <FunctionField label="Next delivery" render={(record) => addonNextDelivery(record)} />
    <FunctionField
      label="Order date"
      sortBy="createdAt"
      render={record => moment(record.createdAt).format('DD/MM/YYYY HH:mm')}
    />
    <FunctionField
      sortBy="cost"
      label="Price"
      render={record => (record.cost ? `£${numeral(record.cost).format('0,0.00')}` : '-')}
    />
    <TextField source="status" />
  </Datagrid>
);

class TabbedDatagrid extends React.Component {
  tabs = [
    { id: 'live', name: 'live tickets' },
    { id: 'past', name: 'past tickets' },
  ];
  state = { live: [], past: [] };

  static getDerivedStateFromProps(props, state) {
    if (props.ids !== state[props.filterValues.status]) {
      return { ...state, [props.filterValues.status]: props.ids };
    }
    return null;
  }

  handleChange = (event, value) => {
    const { filterValues, setFilters } = this.props;
    setFilters({ ...filterValues, status: value });
  };

  render() {
    const { filterValues, ...props } = this.props;

    return (
      <Fragment>
        <Tabs value={filterValues.status} indicatorColor="primary" onChange={this.handleChange}>
          {this.tabs.map(choice => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === 'live' && <Row {...props} />}
          {filterValues.status === 'past' && <Row {...props} />}
        </div>
      </Fragment>
    );
  }
}

const AddonAssignmentList = ({ ...props }) => (
  <List
    {...props}
    bulkActionButtons={<AddonsActionButtons />}
    filters={<AddonAssignmentsFilters />}
    filterDefaultValues={{ status: 'live' }}
  >
    <TabbedDatagrid />
  </List>
);

export default AddonAssignmentList;
