import React from 'react';
import { Edit } from 'react-admin';
import AlertsForm from './AlertsForm';

const AlertsEdit = props => (
  <Edit {...props} undoable={false}>
    <AlertsForm redirect={false} />
  </Edit>
);

export default AlertsEdit;
