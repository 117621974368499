import React from 'react';
import { Show, SimpleShowLayout, FunctionField } from 'react-admin';
import moment from 'moment';
import get from 'lodash/get';

const Actions = () => null;

const InvoicesShow = props => {

  return (
    <Show {...props} actions={<Actions />}>
      <SimpleShowLayout>
        <FunctionField label="Check in" render={record => moment(record.checkIn).format('DD-MM-YYYY HH:mm')} />
        <FunctionField label="Check out" render={record => moment(record.checkOut).format('DD-MM-YYYY HH:mm')} />
        <FunctionField label="Workspace" render={record => `${get(record, 'space.name')}`} />
        <FunctionField label="District" render={record => `${get(record, 'space.district')}`} />
        <FunctionField label="Address" render={record => `${get(record, 'space.address')}`} />
        <FunctionField label="Resource" render={record => get(record, 'resource.name', '')} />
      </SimpleShowLayout>
    </Show>
  );
};


export default InvoicesShow;
