import React from 'react';
import moment from 'moment';
import {
  SimpleForm,
  BooleanInput,
  TextInput,
  LongTextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  required,
  Toolbar,
  SaveButton,
  maxLength,
  ImageField,
  ImageInput,
} from 'react-admin';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { getFormValues, change as changeForm } from 'redux-form';
import { DateTimeInput } from 'react-admin-date-inputs';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Button } from '@material-ui/core';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const formStyles = {
  wordCounterContainer: {
    position: 'relative',
    width: 340,
  },
  wordCounter: {
    position: 'absolute',
    right: 0,
    top: 20,
  },
};

const AlertsEditToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </Toolbar>
));

const validate = values => {
  const errors = {};
  if (moment().isSameOrAfter(moment(values.date))) {
    errors.date = ['Date must be in future.'];
  }
  return errors;
};

const AlertsForm = ({ formValues, change, classes, contentLength, ...props }) => {
  const [defaultSpaceId, setDefaultSpaceId] = React.useState(formValues.spaceId);
  React.useEffect(() => {
    if (defaultSpaceId) {
      change('record-form', 'memberIds', null);
      setDefaultSpaceId(formValues.spaceId);
    } else {
      setDefaultSpaceId(formValues.spaceId);
    }
  }, [formValues.spaceId]);
  return (
    <SimpleForm {...props} toolbar={<AlertsEditToolbar />} validate={validate}>
      <Button
        style={{ marginRight: 'auto', width: 120 }}
        variant="contained"
        size="small"
        color="primary"
        onClick={() => props.history.push('/alerts')}
      >
        {'< Go back'}
      </Button>
      <TextInput source="name" validate={[required()]} />
      <div className={classes.wordCounterContainer}>
        <div className={classes.wordCounter}>{`${contentLength}/150`}</div>
        <LongTextInput
          label="Message content"
          style={{ width: 340 }}
          rows={5}
          source="content"
          validate={[required(), maxLength(150)]}
        />
      </div>
      <BooleanInput label="All" source="all" defaultValue={false} />
      {!formValues.all && (
        <>
          <ReferenceArrayInput label="Businesses" source="spaceIds" reference="spaces">
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            key={defaultSpaceId}
            label="Specific recipients"
            source="memberIds"
            reference="members"
            sort={{ field: 'lastName', order: 'ASC' }}
            filter={{ withDeviceToken: true }}
          >
            <AutocompleteArrayInput
              optionText={({ lastName, firstName, email }) => `${lastName} ${firstName} <${email}>`}
            />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            label="Recipient groups"
            source="memberGroupIds"
            reference="member_groups"
            sort={{ field: 'name', order: 'ASC' }}
          >
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
        </>
      )}
      <DateTimeInput
        source="date"
        label="Date"
        options={{ format: 'dd/MM/YYYY, HH:mm', ampm: false, clearable: true }}
        validate={[required()]}
      />
      <ImageInput source="photo" label="Alert image" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  );
};

const mapStateToProps = state => ({
  contentLength: get(getFormValues('record-form')(state), 'content', '').length || 0,
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = compose(withRouter, withStyles(formStyles), connect(mapStateToProps, { change: changeForm }));

export default enhance(AlertsForm);
