import React from 'react';
import {
  AutocompleteInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  email,
  minValue,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SaveButton,
  TextInput,
  Toolbar,
} from 'react-admin';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { getFormValues, change } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import CheckInChartSection from './CheckInChartSection';
import ExtrasOverview from './ExtrasOverview';
import DocumentsOverview from './DocumentsOverview';
import { DateInput } from 'react-admin-date-inputs';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const formStyles = {
  xeroButton: {
    marginTop: 30,
    marginBottom: 15,
  },
  xeroControls: {
    display: 'flex',
    flexDirection: 'column',
  },
  chartContianer: {
    paddingLeft: 30,
    paddingBottom: 80,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontSize: 24,
    marginBottom: 30,
  },
  goCardlessDetails: {
    margin: '10px 0px',
  },
  checkIcon: {
    marginTop: 10,
  },
};

const SpaceEditToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props} />
  </Toolbar>
));

const SpacesForm = ({ formValues, classes, change, dispatch, ...props }) => {
  const [defaultXeroConfigId, setDefaultXeroConfigId] = React.useState(formValues.xeroConfigId);
  React.useEffect(() => {
    if (defaultXeroConfigId) {
      change('record-form', 'xeroAccountCode', null);
      change('record-form', 'xeroContactId', null);
    } else {
      setDefaultXeroConfigId(formValues.xeroConfigId);
    }
  }, [formValues.xeroConfigId]); // eslint-disable-line
  const onChange = (cardHolderGroupIds) => {
    setTimeout(()=> {
      // hack  to be backwards compatible
      change('record-form', 'cardHolderGroupIds', [last(cardHolderGroupIds)]);
    }, 0)
  };
  return (
    <SimpleForm {...props} toolbar={<SpaceEditToolbar />}>
      <Grid container>
        <Grid item xs={4} className={classes.container}>
          <Typography style={{ fontSize: 24 }}>Business details</Typography>
          <TextInput source="name" label="Workspace" validate={[required()]} />
          <TextInput source="subdomain" label="URL" validate={[required()]} />
          <TextInput source="description" label="Business" validate={[required()]} />
          <NumberInput
            source="desks"
            label="Total Number of Desks"
            validate={[required(), minValue(1)]}
            inputProps={{ min: 1 }}
            defaultValue={1}
          />
          <NumberInput
            source="spaceArea"
            label="Workspace SQFT"
            validate={[required(), minValue(1)]}
            inputProps={{ min: 1 }}
            defaultValue={1}
          />
          {get(props, 'match.params.id') && (
            <NumberInput
              source="totalMembers"
              label="Total Number of Members"
              inputProps={{ disabled: true }}
              defaultValue={1}
            />
          )}
          <ReferenceArrayInput
            filter={{ SiteId: formValues.siteId }}
            source="cardHolderGroupIds"
            reference="act/card_holder_groups"
            label="ACT Card Holder Group"
            onChange={(e, v) => onChange(v)}
          >
            <SelectArrayInput optionText="Name" validate={[required()]} />
          </ReferenceArrayInput>
          <DateInput
            source="licenseAgreement"
            label="License agreement"
            options={{ clearable: true, ampm: false, style: { width: '100%' }, format: 'do MMMM YYYY' }}
          />
          <DateInput
            source="moveInDate"
            label="Move in date"
            options={{ clearable: true, ampm: false, style: { width: '100%' }, format: 'do MMMM YYYY' }}
          />
          <DateInput
            source="moveOutDate"
            label="Move out date"
            options={{ clearable: true, ampm: false, style: { width: '100%' }, format: 'do MMMM YYYY' }}
          />
          <Typography style={{ fontSize: 24, marginTop: 40 }}>Finance details</Typography>
          <TextInput source="financeContact" validate={[required(), email()]} />
          <NumberInput source="subscriptionFee" validate={[required(), minValue(0)]} inputProps={{ min: 0 }} />
          <ReferenceInput source="siteId" reference="act/sites" label="ACT Site">
            <SelectInput optionText="Name" validate={[required()]} />
          </ReferenceInput>
          {props.match.params.id && (
            <div className={classes.goCardlessDetails}>
              <div>DD Details Received?</div>
              {!isEmpty(get(formValues, 'stripe.customerId')) ? (
                <img className={classes.checkIcon} width={22} src="/images/icons/checked.svg" alt="Yes" />
              ) : (
                <img className={classes.checkIcon} width={22} src="/images/icons/unchecked.svg" alt="No" />
              )}
            </div>
          )}
          <NumberInput
            source="flexCredits"
            label="Credits (Flex & Meeting Room for Work.Life in £)"
            validate={[required(), minValue(0)]}
            defaultValue={0}
            inputProps={{ min: 0 }}
          />
          <ReferenceInput
            source="xeroConfigId"
            reference="xero/configs"
            label="Assign xero app"
            validate={[required()]}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          {get(formValues, 'xeroConfigId') && [
            <ReferenceInput
              validate={[required()]}
              source="xeroContactId"
              filter={{ xeroConfigId: get(formValues, 'xeroConfigId') }}
              reference="xero/contacts"
              label="Xero Contact"
            >
              <SelectInput optionText="Name" validate={[required()]} />
            </ReferenceInput>,
            <ReferenceInput
              validate={[required()]}
              source="xeroAccountCode"
              reference="xero/accounts"
              label="Xero Account Code"
              filter={{ xeroConfigId: get(formValues, 'xeroConfigId') }}
            >
              <SelectInput optionText="Name" validate={[required()]} />
            </ReferenceInput>,
          ]}
          <ReferenceInput
            source="xeroFlexSessionAccountCode"
            reference="xero/default_accounts"
            label="Xero Flex Session Account Code"
          >
            <SelectInput optionText="Name" validate={[required()]} />
          </ReferenceInput>
          <ReferenceInput
            source="xeroFlexBookingAccountCode"
            reference="xero/default_accounts"
            label="Xero Meeting Room Booking Account Code"
          >
            <SelectInput optionText="Name" validate={[required()]} />
          </ReferenceInput>
        </Grid>
        <Grid item xs={8} style={{ padding: '0 30px' }}>
          {props.match.params.id && (
            <>
              <CheckInChartSection spaceId={props.match.params.id} />
              <ExtrasOverview spaceId={props.match.params.id} />
              <DocumentsOverview spaceId={props.match.params.id} />
            </>
          )}
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
});
const mapDispatchToProps = { change };

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withStyles(formStyles), withRouter);

export default enhance(SpacesForm);
