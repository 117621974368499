import { SET_GLOBAL_SPACE_IDS, TOGGLE_GLOBAL_SPACE_ID } from './globalFiltersActions';
import { DEFAULT_SPACE_TOKEN_KEY } from '../../_app/constants';

const INITIAL_STATE = { spaceIds: JSON.parse(localStorage.getItem(DEFAULT_SPACE_TOKEN_KEY) || '[]') };

export default (initialSate = INITIAL_STATE, action) => {
  if (action.type === SET_GLOBAL_SPACE_IDS) {
    return {
      spaceIds: action.spaceIds,
    };
  }
  if (action.type === TOGGLE_GLOBAL_SPACE_ID) {
    let spaceIds = [];
    if (action.checked) {
      spaceIds = [...initialSate.spaceIds, action.spaceId];
    } else {
      spaceIds = initialSate.spaceIds.filter(spaceId => spaceId !== action.spaceId);
    }
    return {
      spaceIds,
    };
  }

  return initialSate;
};
