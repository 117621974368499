import moment from 'moment';
import React, { Fragment } from 'react';
import {
  Datagrid,
  AutocompleteInput,
  Filter,
  List,
  ReferenceInput,
  FunctionField,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin';
import { getTimezone } from '../utils';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import BulkExportButton from '../_common/components/BulkExportButton';

const ResourcesActionButtons = props => (
  <Fragment>
    <BulkExportButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </Fragment>
);

const ResourcesFilters = props => (
  <Filter {...props}>
    {/* <ReferenceInput label="Workspace" source="spaceId" reference="spaces" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput> */}
    <TextInput source="name" />
  </Filter>
);

const ResourceList = props => (
  <List
    {...props}
    filters={<ResourcesFilters />}
    bulkActionButtons={<ResourcesActionButtons />}
  >
    <Datagrid rowClick="edit">
      <ReferenceField
        linkType={false}
        allowEmpty
        source="spaceId"
        reference="spaces"
        sortable={false}
        label="Workspace"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        linkType={false}
        allowEmpty
        source="spaceId"
        reference="spaces"
        sortable={false}
        label="Business"
      >
        <TextField source="description" />
      </ReferenceField>
      <TextField source="name" />
      <FunctionField
        label={`Booking times (start) ${getTimezone()}`}
        render={record =>
          `${moment(record.from).format('HH:mm')}`
        }
      />
      <FunctionField
        label={`Booking times (end) ${getTimezone()}`}
        render={record =>
          `${moment(record.to).format('HH:mm')}`
        }
      />
    </Datagrid>
  </List>
);
export default ResourceList;
