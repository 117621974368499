import React from 'react';
import { AppBar } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  logoImage: {
    margin: '0 auto',
  },
  covidInfo: {
    margin: '10px auto',
  },
  covidLink: {
    color: 'rgb(37, 179, 176)',
    '&:hover': {
      color: 'rgb(31, 142, 139)',
    }
  }
};

const CustomAppBar = ({ classes, ...props }) => (
  <>
  <AppBar {...props}>
    <img src="/images/logo-fully-white.svg" width={100} alt="yours" className={classes.logoImage} />
  </AppBar>
  <div className={classes.covidInfo}>Please read our <a href="https://work.life/covid-19-policy/" className={classes.covidLink} target="_blank" rel="noopener noreferrer">COVID-19 policy.</a></div>
  </>
);

export default withStyles(styles)(CustomAppBar);
