import Button from '@material-ui/core/Button';
import Attachment from '@material-ui/icons/Attachment';
import React, { Fragment } from 'react';
import {
  FunctionField,
  BooleanInput,
  SelectInput,
  ReferenceField,
  CardActions,
  CreateButton,
  Datagrid,
  ExportButton,
  List,
  TextField,
  BooleanField,
  Filter,
  TextInput,
} from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { ROLES } from '../_app/constants';
import MembersInviteButton from './MembersInviteButton';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import BulkExportButton from '../_common/components/BulkExportButton';

const MembersFilters = props => (
  <Filter {...props}>
    <TextInput source="email" alwaysOn />
    <TextInput source="firstName" />
    <TextInput source="lastName" />
    <BooleanInput source="invited" />
    {/* <ReferenceInput label="Workspace" source="spaceId" reference="spaces" alwaysOn>
      <AutocompleteInput optionText={choice => choice.name} />
    </ReferenceInput> */}
    <SelectInput source="role" choices={ROLES} />
  </Filter>
);

const MembersActionButtons = props => (
  <Fragment>
    <BulkExportButton {...props} />
    <MembersInviteButton label="Send Invitations" {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </Fragment>
);

const MembersListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  push,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <Button size="small" color="primary" onClick={() => push('/membersImports/create')}>
      <Attachment style={{ width: 20, height: 20, marginRight: 6.5 }} />
      Import
    </Button>
  </CardActions>
);

const MembersList = props => (
  <List
    {...props}
    filters={<MembersFilters />}
    bulkActionButtons={<MembersActionButtons />}
    actions={<MembersListActions push={props.push} />}
  >
    <Datagrid rowClick="edit">
      <TextField source="email" />
      <BooleanField source="invited" label="Invited" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <ReferenceField
        sortable={false}
        allowEmpty
        linkType={false}
        source="cardId"
        reference="cards"
        label="Primary Access Serial #"
      >
        <FunctionField render={({ serialNumber }) => serialNumber || '-'} />
      </ReferenceField>
      <TextField source="role" />
      <ReferenceField linkType={false} source="spaceId" reference="spaces" label="Workspace">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default connect(null, { push })(MembersList);
