import React from 'react';
import { Admin, Resource } from 'react-admin';
import addUploadFeature from './addUploadFeature';
import authProvider from './authProvider';
import BookingsCreate from './bookings/BookingsCreate';
import BookingsEdit from './bookings/BookingsEdit';
import BookingsList from './bookings/BookingsList';
import ExternalBookingsList from './externalBookings/ExternalBookingsList';
import ExternalBookingsShow from './externalBookings/ExternalBookingsShow';
import CardsCreate from './cards/CardsCreate';
import CardsEdit from './cards/CardsEdit';
import CardsList from './cards/CardsList';
import CardsImportCreate from './cardsImport/CardsImportCreate';
import CompaniesCreate from './companies/CompaniesCreate';
import CompaniesEdit from './companies/CompaniesEdit';
import { CompaniesList } from './companies/CompaniesList';
import dataProvider from './dataProvider';
import errorsSaga from './sideEffects/errorsSaga';
import './index.css';
import MembersCreate from './members/MembersCreate';
import MembersEdit from './members/MembersEdit';
import MembersList from './members/MembersList';
import MembersImportCreate from './membersImport/MembersImportCreate';
import ResourcesCreate from './resources/ResourcesCreate';
import ResourcesEdit from './resources/ResourcesEdit';
import ResourcesList from './resources/ResourcesList';
import SpacesCreate from './spaces/SpacesCreate';
import SpacesEdit from './spaces/SpacesEdit';
import SpacesList from './spaces/SpacesList';
import InvoicesCreate from './invoices/InvoicesCreate';
import InvoicesList from './invoices/InvoicesList';
import InvoicesShow from './invoices/InvoicesShow';
import InvoicesEdit from './invoices/InvoicesEdit';
import FlexSessionsShow from './flexSessions/FlexSessionsShow';
import FlexSessionsList from './flexSessions/FlexSessionsList';
import { yoursTheme } from './_app/theme';
import {
  spaceIcon,
  memberIcon,
  resourceIcon,
  bookingIcon,
  companyIcon,
  cardIcon,
  invoiceIcon,
  flexIcon,
  faqIcon,
  xeroIcon,
  addonIcon,
  documentsIcon,
  policiesIcon,
  alertsIcon,
  chatsIcon,
} from './_app/icons';
import Layout from './layout/Layout';
import Login from './layout/Login';
import BookingsShow from './bookings/BookingsShow';
import FaqsList from './faqs/FaqsList';
import FaqsEdit from './faqs/FaqsEdit';
import FaqsCreate from './faqs/FaqsCreate';
import FaqCategoriesList from './faqCategories/FaqCategoriesList';
import FaqsCategoriesCreate from './faqCategories/FaqCategoriesCreate';
import FaqCategoriesEdit from './faqCategories/FaqCategoriesEdit';
import XeroConfigsList from './xeroConfigs/XeroConfigsList';
import XeroConfigsCreate from './xeroConfigs/XeroConfigsCreate';
import XeroConfigsEdit from './xeroConfigs/XeroConfigsEdit';
import AddonCategoriesList from './addonCategories/AddonCategoriesList';
import AddonCategoriesCreate from './addonCategories/AddonCategoriesCreate';
import AddonCategoriesEdit from './addonCategories/AddonCategoriesEdit';
import AddonsList from './addons/AddonsList';
import AddonsEdit from './addons/AddonsEdit';
import AddonsCreate from './addons/AddonsCreate';
import AddonAssignmentsList from './addonAssignments/AddonAssignmentsList';
import AddonAssignmentsEdit from './addonAssignments/AddonAssignmentsEdit';
import DocumentsCreate from './documents/DocumentsCreate';
import DocumentsEdit from './documents/DocumentsEdit';
import DocumentsList from './documents/DocumentsList';
import PoliciesList from './policies/PoliciesList';
import PoliciesCreate from './policies/PoliciesCreate';
import PoliciesEdit from './policies/PoliciesEdit';
import withListingTitle from './hoc/withListingTitle';
import AlertsList from './alerts/AlertsList';
import AlertsCreate from './alerts/AlertsCreate';
import AlertsEdit from './alerts/AlertsEdit';
import ChatEdit from './chat/ChatEdit';
import ChatList from './chat/ChatList';
import MemberGroupsList from './memberGroups/MemberGroupsList';
import MemberGroupsCreate from './memberGroups/MemberGroupsCreate';
import MemberGroupsEdit from './memberGroups/MemberGroupsEdit';
import globalFilters from './sideEffects/globalFilters/globalFiltersReducer';
import AddonAssignmentsCreate from './addonAssignments/AddonAssignmentsCreate';

const dataProviderWithUploadFeature = addUploadFeature(dataProvider);

const App = () => (
  <Admin
    theme={yoursTheme}
    dataProvider={dataProviderWithUploadFeature}
    customSagas={[errorsSaga]}
    customReducers={{ globalFilters }}
    authProvider={authProvider}
    appLayout={Layout}
    loginPage={Login}
  >
    <Resource
      name="spaces"
      options={{ label: 'Workspace info' }}
      list={withListingTitle('Workspaces')(SpacesList)}
      edit={SpacesEdit}
      create={SpacesCreate}
      icon={spaceIcon}
    />
    <Resource
      name="members"
      list={withListingTitle()(MembersList)}
      create={MembersCreate}
      edit={MembersEdit}
      icon={memberIcon}
    />
    <Resource options={{ label: 'Members Import' }} name="membersImports" create={MembersImportCreate} />
    <Resource options={{ label: 'Cards Import' }} name="cardsImports" create={CardsImportCreate} />
    <Resource
      name="resources"
      list={withListingTitle('Meeting Rooms')(ResourcesList)}
      edit={ResourcesEdit}
      create={ResourcesCreate}
      icon={resourceIcon}
      options={{
        label: 'Meeting Rooms',
      }}
    />
    <Resource
      name="bookings"
      list={withListingTitle('Internal bookings')(BookingsList)}
      create={BookingsCreate}
      edit={BookingsEdit}
      icon={bookingIcon}
      show={BookingsShow}
      options={{
        label: 'Internal bookings',
      }}
    />
    <Resource
      name="external_bookings"
      list={withListingTitle()(ExternalBookingsList)}
      icon={bookingIcon}
      show={ExternalBookingsShow}
    />
    <Resource
      name="companies"
      list={withListingTitle('Landlords')(CompaniesList)}
      create={CompaniesCreate}
      edit={CompaniesEdit}
      icon={companyIcon}
      options={{
        label: 'Landlords',
      }}
    />
    <Resource
      name="invoices"
      list={withListingTitle()(InvoicesList)}
      show={InvoicesShow}
      create={InvoicesCreate}
      edit={InvoicesEdit}
      icon={invoiceIcon}
      options={{
        label: 'Invoices',
      }}
    />
    <Resource
      name="cards"
      list={withListingTitle('Access Cards')(CardsList)}
      create={CardsCreate}
      edit={CardsEdit}
      icon={cardIcon}
      options={{
        label: 'Access Cards',
      }}
    />
    <Resource
      name="flex_sessions"
      list={withListingTitle()(FlexSessionsList)}
      show={FlexSessionsShow}
      icon={flexIcon}
      options={{
        label: 'Flex sessions',
      }}
    />
    <Resource
      name="documents"
      list={withListingTitle()(DocumentsList)}
      create={DocumentsCreate}
      edit={DocumentsEdit}
      icon={documentsIcon}
      options={{
        label: 'Documents',
      }}
    />
    <Resource
      name="addons"
      list={withListingTitle('Extras - App Management')(AddonsList)}
      create={AddonsCreate}
      edit={AddonsEdit}
      icon={addonIcon}
      options={{
        label: 'Extras - App',
        isAppManagement: true,
      }}
    />
    <Resource
      name="addon_assignments"
      list={withListingTitle('Extras - Admin')(AddonAssignmentsList)}
      edit={AddonAssignmentsEdit}
      create={AddonAssignmentsCreate}
      icon={addonIcon}
      options={{
        label: 'Extras - Admin',
      }}
    />
    <Resource
      name="policies"
      list={withListingTitle()(PoliciesList)}
      create={PoliciesCreate}
      edit={PoliciesEdit}
      icon={policiesIcon}
    />
    <Resource
      name="xero/configs"
      list={withListingTitle('Xero Configs')(XeroConfigsList)}
      create={XeroConfigsCreate}
      edit={XeroConfigsEdit}
      icon={xeroIcon}
      options={{
        label: 'Xero Configs',
      }}
    />
    <Resource
      name="chats"
      list={withListingTitle('Chats')(ChatList)}
      edit={ChatEdit}
      icon={chatsIcon}
      options={{
        label: 'Chat',
      }}
    />
    <Resource
      name="support/faq_categories"
      list={withListingTitle('FAQ Categories')(FaqCategoriesList)}
      create={FaqsCategoriesCreate}
      edit={FaqCategoriesEdit}
    />
    <Resource
      name="support/faqs"
      list={withListingTitle('FAQs')(FaqsList)}
      create={FaqsCreate}
      edit={FaqsEdit}
      icon={faqIcon}
      options={{
        label: 'FAQs',
        isAppManagement: true,
      }}
    />
    <Resource
      name="alerts"
      list={withListingTitle('Alerts')(AlertsList)}
      create={AlertsCreate}
      edit={AlertsEdit}
      icon={alertsIcon}
      options={{
        label: 'Alerts',
        isAppManagement: true,
      }}
    />
    <Resource
      name="addon_categories"
      list={withListingTitle('Extras Categories')(AddonCategoriesList)}
      create={AddonCategoriesCreate}
      edit={AddonCategoriesEdit}
    />
    <Resource
      name="member_groups"
      list={withListingTitle('Recipient Groups')(MemberGroupsList)}
      create={MemberGroupsCreate}
      edit={MemberGroupsEdit}
    />

    <Resource name="policies/types" />
    <Resource name="act/sites" />
    <Resource name="act/card_holder_groups" />
    <Resource name="xero/contacts" />
    <Resource name="xero/accounts" />
    <Resource name="xero/default_accounts" />
  </Admin>
);

export default App;
