import Space from '@material-ui/icons/Landscape';
import Room from '@material-ui/icons/Room';
import Card from '@material-ui/icons/CreditCard';
import Event from '@material-ui/icons/Event';
import People from '@material-ui/icons/People';
import Account from '@material-ui/icons/AccountBalance';
import Invoice from '@material-ui/icons/MonetizationOn';
import FlexSession from '@material-ui/icons/CardMembership';
import Faq from '@material-ui/icons/QuestionAnswer';
import Note from '@material-ui/icons/Note';
import Add from '@material-ui/icons/AddCircleOutline';
import FolderOpen from '@material-ui/icons/FolderOpen';
import Assignment from '@material-ui/icons/Assignment';
import Notifications from '@material-ui/icons/Notifications';
import Chat from '@material-ui/icons/Chat';

export const spaceIcon = Space;
export const memberIcon = People;
export const resourceIcon = Room;
export const bookingIcon = Event;
export const companyIcon = Account;
export const cardIcon = Card;
export const invoiceIcon = Invoice;
export const flexIcon = FlexSession;
export const faqIcon = Faq;
export const xeroIcon = Note;
export const addonIcon = Add;
export const documentsIcon = FolderOpen;
export const policiesIcon = Assignment;
export const alertsIcon = Notifications;
export const chatsIcon = Chat;
