import React, { Fragment } from 'react';
import get from 'lodash/get';
import API, { toggleGlobalSpaceIdParam } from '../_app/api';
import {
  ReferenceArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceInput,
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import { connect } from 'react-redux';
import { toggleGlobalSpaceId } from '../sideEffects/globalFilters/globalFiltersActions';
import Checkbox from '@material-ui/core/Checkbox';
import BulkExportButton from '../_common/components/BulkExportButton';

const SpacesActionButtons = props => (
  <Fragment>
    <BulkExportButton {...props} />
  </Fragment>
);

const SpacesFilters = props => (
  <Filter {...props}>
    <TextInput source="name" />
    <ReferenceInput label="ACT Site Name" source="siteId" reference="act/sites">
      <AutocompleteInput optionText={choice => choice.Name} />
    </ReferenceInput>
    <ReferenceArrayInput label="ACT Card Holder Groups" source="cardHolderGroupIds" reference="act/card_holder_groups">
      <AutocompleteArrayInput optionText={choice => choice.Name} />
    </ReferenceArrayInput>
  </Filter>
);

// const FilterCheckbox = ({ toggleSpaceId, selectedGlobalSpaceIds, record, ...rest }) => (
//   <Checkbox
//     checked={selectedGlobalSpaceIds.find(spaceId => spaceId === record._id)}
//     onChange={e => {
//       toggleGlobalSpaceIdParam(e.target.checked, record._id);
//       toggleSpaceId(e.target.checked, record._id);
//     }}
//     onClick={e => e.stopPropagation()}
//   />
// );

const SpacesList = props => {
  React.useEffect(() => {
    if (props.selectedGlobalSpaceIds.length === 1) {
      props.history.push(`/spaces/${props.selectedGlobalSpaceIds[0]}`);
    }
  }, []);
  return (
    <List {...props} filters={<SpacesFilters />} bulkActionButtons={<SpacesActionButtons />}>
      <Datagrid rowClick="edit">
        <TextField source="name" label="Workspace" />
        <TextField source="description" label="Business" />
        <ReferenceArrayField source="cardHolderGroupIds" reference="act/card_holder_groups" label="ACT Access Groups">
          <SingleFieldList linkType={false}>
            <ChipField source="Name" />
          </SingleFieldList>
        </ReferenceArrayField>
        {/* <FilterCheckbox
          selectedGlobalSpaceIds={props.selectedGlobalSpaceIds}
          label="Add to filter"
          toggleSpaceId={props.toggleGlobalSpaceId}
        /> */}
      </Datagrid>
    </List>
  );
};

const mapStateToProps = state => ({
  selectedGlobalSpaceIds: state.globalFilters.spaceIds,
});

export default connect(mapStateToProps, { toggleGlobalSpaceId })(SpacesList);
