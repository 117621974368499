import React from 'react';
import { Edit } from 'react-admin';
import ChatForm from './ChatForm';

const ChatEdit = props => (
  <Edit {...props} undoable={false}>
    <ChatForm redirect={false} />
  </Edit>
);

export default ChatEdit;
