import React from 'react';
import { Create } from 'react-admin';
import XeroConfigsForm from './XeroConfigsForm';

const XeroConfigsCreate = props => (
  <Create {...props}>
    <XeroConfigsForm />
  </Create>
);

export default XeroConfigsCreate;
