import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  // ReferenceArrayInput,
  // AutocompleteArrayInput,
  // AutocompleteInput,
  // ReferenceInput,
  // Filter,
  // TextInput,
} from 'react-admin';

// const XeroConfigsFilters = props => (
//   <Filter {...props}>
//     <TextInput source="name" />
//     <ReferenceInput label="ACT Site Name" source="siteId" reference="act/sites">
//       <AutocompleteInput optionText={choice => choice.Name} />
//     </ReferenceInput>
//     <ReferenceArrayInput label="ACT Card Holder Groups" source="cardHolderGroupIds" reference="act/card_holder_groups">
//       <AutocompleteArrayInput optionText={choice => choice.Name} />
//     </ReferenceArrayInput>
//   </Filter>
// );

const XeroConfigsList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="appType" sortable={false} />
    </Datagrid>
  </List>
);

export default XeroConfigsList;
