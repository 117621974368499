import React from 'react';
import { Datagrid, List, TextField, Filter, ReferenceInput, AutocompleteInput, TextInput } from 'react-admin';
import BulkExportButton from '../_common/components/BulkExportButton';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';

const CompaniesFilters = props => (
  <Filter {...props}>
    {/* <ReferenceInput label="Workspace" source="spaceId" reference="spaces">
      <AutocompleteInput optionText="name" />
    </ReferenceInput> */}
    <TextInput source="name" />
    <TextInput source="address" />
    <TextInput source="vat" />
  </Filter>
);

const CompaniesActionButtons = props => (
  <>
    <BulkExportButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </>
);

export const CompaniesList = props => (
  <List {...props} filters={<CompaniesFilters />} bulkActionButtons={<CompaniesActionButtons />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="address" />
      <TextField source="vat" />
    </Datagrid>
  </List>
);

export default CompaniesList;
