import React from 'react';
import { Edit } from 'react-admin';
import CardsForm from './CardsForm';

const CardsEdit = props => (
  <Edit {...props} undoable={false}>
    <CardsForm redirect={false} />
  </Edit>
);

export default CardsEdit;
