import React from 'react';
import { Create } from 'react-admin';
import MemberGroupsForm from './MemberGroupsForm';

const MemberGroupsCreate = props => (
  <Create {...props}>
    <MemberGroupsForm redirect="edit" />
  </Create>
);

export default MemberGroupsCreate;
