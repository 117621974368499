import React from 'react';
import { Create } from 'react-admin';
import DocumentsForm from './DocumentsForm';

const DocumentsCreate = props => (
  <Create {...props} redirect="edit">
    <DocumentsForm />
  </Create>
);

export default DocumentsCreate;
