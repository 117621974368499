import React from 'react';
import { Edit } from 'react-admin';
import FaqsForm from './FaqCategoriesForm';

const FaqCategoriesEdit = props => (
  <Edit {...props} undoable={false}>
    <FaqsForm redirect={false} />
  </Edit>
);

export default FaqCategoriesEdit;
