import React from 'react';
import {
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SimpleForm,
  TextInput,
  required,
  Toolbar,
  SaveButton,
} from 'react-admin';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const MemberGroupsEditToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </Toolbar>
));

const MemberGroupsCategories = ({ ...props }) => (
  <SimpleForm {...props} toolbar={<MemberGroupsEditToolbar />}>
    <TextInput source="name" validate={[required()]} />
    <ReferenceArrayInput label="Members" source="memberIds" filter={{ withDeviceToken: true }} reference="members" validate={[required()]}>
      <AutocompleteArrayInput optionText={({ lastName, firstName }) => `${lastName} ${firstName}`} />
    </ReferenceArrayInput>
  </SimpleForm>
);

export default MemberGroupsCategories;
