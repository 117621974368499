import moment from 'moment-timezone';
import values from 'lodash/values';
import join from 'lodash/join';
import chunk from 'lodash/chunk';
import map from 'lodash/map';
import get from 'lodash/get';
import pick from 'lodash/pick';

export const getTimezone = () => moment().tz(moment.tz.guess()).format('z');

export const pretifyAndShortenColleciton = (collection, keys = ['name'], chunkSize = 2, emptyMessage = '-') => {
  let [itemsToShow = [], restItems = []] = chunk(values(collection), chunkSize);
  if (!itemsToShow.length) {
    return emptyMessage;
  }
  let str = map(itemsToShow, item => join(values(pick(item, keys)), ' '));
  str = join(str, ', ');
  if (restItems.length > 0) {
    str += ` +${restItems.length} more`;
  }
  return str;
};

export const addonNextDelivery = ({ startsAt, recurrenceRule }) => {
  if (!recurrenceRule) {
    return '-';
  }
  const now = moment().startOf('day');
  startsAt = moment(startsAt);

  if (recurrenceRule === 'daily') {
    while (startsAt.isBefore(now) || startsAt.day() === 0 || startsAt.day() === 6) {
      startsAt = startsAt.add({ day: 1 });
    }
  }
  if (recurrenceRule === 'weekly') {
    while (startsAt.isBefore(now)) {
      startsAt = startsAt.add({ day: 7 });
    }
  }
  if (recurrenceRule === 'every2weeks') {
    while (startsAt.isBefore(now)) {
      startsAt = startsAt.add({ day: 14 });
    }
  }
  if (recurrenceRule === 'monthly') {
    const day = startsAt.day();
    let weekDayInMonthNumber = 1;
    while (weekDayInMonthNumber * 7 < startsAt.date()) {
      weekDayInMonthNumber++;
    }
    const firstMonthDay = startsAt
      .add({ month: 1 })
      .startOf('month')
      .day(day + 1);
    if (startsAt.month() !== firstMonthDay.month()) {
      startsAt.add({ days: 7 });
    }
    const month = startsAt.month();
    startsAt.add({ days: weekDayInMonthNumber * 7 - 1 });
    if (month !== startsAt.month()) {
      startsAt.subtract({ days: 7 });
    }
  }
  return startsAt.format('DD/MM/YYYY');
};
