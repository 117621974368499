import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { Create, FileField, FileInput, ReferenceInput, AutocompleteInput, SimpleForm } from 'react-admin';

const MembersImportCreate = props => {
  return (
    <Create {...props} title="Members Import">
      <SimpleForm redirect="/members">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>First name</TableCell>
              <TableCell>Last name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Job title</TableCell>
              <TableCell>Card Serial #</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>...</TableCell>
              <TableCell>...</TableCell>
              <TableCell>...</TableCell>
              <TableCell>...</TableCell>
              <TableCell>...</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <FileInput source="csv" label="CSV"
                   accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
          <FileField source="csv" title="title"/>
        </FileInput>
        <ReferenceInput label="Workspace" source="spaceId" reference="spaces">
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default MembersImportCreate;
