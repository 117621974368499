import React from 'react';
import { Divider, Tab, Tabs } from '@material-ui/core';
import moment from 'moment';
import { Datagrid, List, ReferenceField, FunctionField, TextField } from 'react-admin';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import { withStyles } from '@material-ui/core/styles';
import BulkExportButton from '../_common/components/BulkExportButton';

const styles = {
  '@global': {
    'th:first-of-type': {
      width: '90px',
    },
  },
};

const ChatActionButtons = props => (
  <>
    <BulkExportButton {...props} />
  </>
);

const Row = props => (
  <Datagrid rowClick="edit" {...props}>
    <ReferenceField
      linkType={false}
      sortable={false}
      label="Member name"
      allowEmpty
      source="userId"
      reference="members"
    >
      <TextField source="firstName" />
    </ReferenceField>
    <ReferenceField linkType={false} sortable={false} label="Email" allowEmpty source="userId" reference="members">
      <TextField source="email" />
    </ReferenceField>
    <FunctionField label="Date/Time" render={record => moment(record.createdAt).format('DD MMM YYYY HH:mm')} />
    <FunctionField label="Closed" render={record => (record.closed ? 'Yes' : 'No')} />
    <TextField source="urgency" />
  </Datagrid>
);

class TabbedDatagrid extends React.Component {
  tabs = [
    { id: 'live', name: 'live tickets' },
    { id: 'past', name: 'past tickets' },
  ];
  state = { live: [], past: [] };

  static getDerivedStateFromProps(props, state) {
    if (props.ids !== state[props.filterValues.status]) {
      return { ...state, [props.filterValues.status]: props.ids };
    }
    return null;
  }

  handleChange = (event, value) => {
    const { filterValues, setFilters } = this.props;
    setFilters({ ...filterValues, status: value });
  };

  render() {
    const { filterValues, ...props } = this.props;

    return (
      <>
        <Tabs value={filterValues.status} indicatorColor="primary" onChange={this.handleChange}>
          {this.tabs.map(choice => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === 'live' && <Row {...props} />}
          {filterValues.status === 'past' && <Row {...props} />}
        </div>
      </>
    );
  }
}

const ChatList = ({ ...props }) => (
  <List {...props} bulkActionButtons={<ChatActionButtons />} filterDefaultValues={{ status: 'live' }}>
    <TabbedDatagrid />
  </List>
);

export default withStyles(styles)(ChatList);
