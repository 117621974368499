import React from 'react';
import { Edit } from 'react-admin';
import AddonForm from './AddonCategoriesForm';

const AddonCategoriesEdit = props => (
  <Edit {...props} undoable={false}>
    <AddonForm redirect={false} />
  </Edit>
);

export default AddonCategoriesEdit;
