import React from 'react';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { TextInput, ReferenceInput, SimpleForm, required, Toolbar, SaveButton, SelectInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Button } from '@material-ui/core';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const PoliciesEditToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </Toolbar>
));

const PoliciesForm = ({ formValues, ...props }) => (
  <SimpleForm {...props} toolbar={<PoliciesEditToolbar />}>
    <Button
      style={{ marginRight: 'auto', width: 120 }}
      variant="contained"
      size="small"
      color="primary"
      onClick={() => props.history.push('/policies')}
    >
      {'< Go back'}
    </Button>
    <TextInput source="title" validate={[required()]} />
    <ReferenceInput label="Type" source="type" reference="policies/types" validate={[required()]}>
      <SelectInput optionText="label" />
    </ReferenceInput>
    <div className="custom-ql-container">
      <RichTextInput source="description" validation={{ required: true }} />
    </div>
  </SimpleForm>
);

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = compose(withRouter, connect(mapStateToProps));

export default enhance(PoliciesForm);
