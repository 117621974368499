import React from 'react';
import { Create } from 'react-admin';
import CardsForm from './CardsForm';

const CardsCreate = props => (
  <Create {...props}>
    <CardsForm redirect="/cards" />
  </Create>
);

export default CardsCreate;
