import moment from 'moment';
import get from 'lodash/get';
import React from 'react';
import {
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  FunctionField
} from 'react-admin';
import { DateInput, TimeInput } from 'react-admin-date-inputs';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { getFormValues, change } from 'redux-form';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import CustomAutocomplete from '../_common/components/Autocomplete';
import { getTimezone } from '../utils';
import { RECURRENCE_RULE_MAP } from '../_app/constants';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const BookingEditToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </Toolbar>
));

const validate = values => {
  const errors = {};

  if (moment(values.checkIn).isSameOrAfter(values.checkOut)) {
    errors.checkOut = ['Must be after check in'];
  }
  return errors;
};

const BookingsForm = ({
  change,
  formValues: { when, ...values },
  spaces,
  ...props
}) => {
  const [isResourceChanging, setIsResourceChanging] = React.useState(false);
  React.useEffect(() => {
    if (values.memberId && isResourceChanging) {
      change('record-form', 'memberId', null);
    }
  }, [values.resourceId]); // eslint-disable-line
  return (
    <SimpleForm
      {...props}
      validate={validate}
      toolbar={<BookingEditToolbar />}
    >
      <Button
        style={{ marginRight: 'auto', width: 120 }}
        variant="contained"
        size="small"
        color="primary"
        onClick={() => props.history.push('/bookings')}
      >
        {'< Go back'}
      </Button>
      <DateInput
        source="when"
        label="When"
        options={{ clearable: true, ampm: false }}
        validate={[required()]}
        onChange={(_, value) => {
          if (value) {
            const checkIn = moment(values.checkIn);
            const checkOut = moment(values.checkOut);
            const newCheckIn = moment(value)
              .hours(checkIn.hour())
              .minutes(checkIn.minutes());
            const newCheckOut = moment(value)
              .hours(checkOut.hour())
              .minutes(checkOut.minutes());
            change('record-form', 'checkIn', newCheckIn);
            change('record-form', 'checkOut', newCheckOut);
          }
        }}
        defaultValue={values.checkIn ? moment(values.checkIn).format('YYYY-MM-DD') : null}
      />
      <TimeInput
        source="checkIn"
        label={`Check in ${getTimezone()}`}
        parse={value =>
          value &&
          moment(when)
            .startOf('day')
            .add({
              hours: moment(value).hours(),
              minutes: moment(value).minutes(),
            })
        }
        options={{ clearable: true, ampm: false }}
        validate={[required()]}
      />
      <TimeInput
        source="checkOut"
        label={`Check out ${getTimezone()}`}
        options={{ clearable: true, ampm: false }}
        parse={value =>
          value &&
          moment(when)
            .startOf('day')
            .add({
              hours: moment(value).hours(),
              minutes: moment(value).minutes(),
            })
        }
        validate={[required()]}
      />
      <ReferenceInput
        source="resourceId"
        label="Meeting Room"
        reference="resources"
        validate={[required()]}
        onChange={() => setIsResourceChanging(true)}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <CustomAutocomplete optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Member"
        source="memberId"
        reference="members"
        validate={[required()]}
        filter={{ spaceId: get(spaces[values.resourceId], 'spaceId', null) }}
      >
        <CustomAutocomplete
          allowEmpty
          optionText={({ firstName, lastName }) => `${firstName} ${lastName}`}
        />
      </ReferenceInput>
      <TextInput source="title" validate={[required()]} />
      <TextInput source="comments" />
      {values.recurringBookingId && [
        <FunctionField key="recurrence-rule" label="Recurrence rule" render={record => RECURRENCE_RULE_MAP[record.recurrenceRule]} />,
        <FunctionField key="recurrence-end" label="Recurrence end" render={record => moment(record.recurrenceEnd).format('DD.MM.YYYY')} />
      ]}
    </SimpleForm>
  );
};

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
  spaces: get(state.admin, 'resources.resources.data', {}),
});

const mapDispatchToProps = {
  change,
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(BookingsForm);
