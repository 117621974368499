import React from 'react';
import { Edit } from 'react-admin';
import PoliciesForm from './PoliciesForm';

const PoliciesEdit = props => (
  <Edit {...props} undoable={false}>
    <PoliciesForm redirect={false} />
  </Edit>
);

export default PoliciesEdit;
