import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography, withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import UserActivityBarChart from './UserActivityBarChart';
import get from 'lodash/get';

const styles = theme => ({
  header: {
    display: 'flex',
    marginBottom: 16,
    width: '100%',
    justifyContent: 'space-between',
  },
  formControl: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  chartContianer: {
    paddingBottom: 80,
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontSize: 24,
  },
});

const OPTIONS = [
  { value: '?isDaily=true', label: 'Daily' },
  {
    value: `?isDaily=false&from=${moment()
      .subtract({ days: 7 })
      .format('YYYY-MM-DD')}`,
    label: 'Weekly',
  },
];

const calculateChartHeight = numberOfItems => {
  if (numberOfItems < 40) {
    return 500;
  }
  if (numberOfItems < 70) {
    return 1000;
  }
  if (numberOfItems < 100) {
    return 2000;
  }
  return 3500;
};

class GlobalFilters extends React.Component {
  state = {
    filter: '?isDaily=true',
    data: {},
  };

  async componentDidMount() {
    this.fetchChartData();
  }

  fetchChartData = async () => {
    try {
      const {
        data: { data },
      } = await API(`${API_ROUTES.CHECKIN_REPORTS(this.props.spaceId)}${this.state.filter}`);
      this.setState({ data });
    } catch (e) {
      // handle errors
    }
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.fetchChartData();
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.header}>
          <Typography className={classes.heading}>Check-ins</Typography>
          <Select
            value={this.state.filter}
            onChange={this.handleChange}
            inputProps={{
              name: 'filter',
              id: 'filter',
            }}
          >
            {OPTIONS.map(({ value, label }) => (
              <MenuItem value={value}>{label}</MenuItem>
            ))}
          </Select>
        </div>
        <div
          className={classes.chartContianer}
          style={{ maxHeight: calculateChartHeight(get(this.state.data, 'labels', []).length) }}
        >
          <UserActivityBarChart {...this.state.data} />
        </div>
      </>
    );
  }
}

GlobalFilters.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GlobalFilters);
