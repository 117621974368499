import React from 'react';
import {
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SimpleForm,
  LongTextInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  Toolbar,
  SaveButton,
} from 'react-admin';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  width: {
    '& input': {
      width: 256,
    },
  },
};

const FaqsEditToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </Toolbar>
));

const FaqsForm = ({ ...props }) => (
  <SimpleForm {...props} toolbar={<FaqsEditToolbar />}>
    <Button
      style={{ marginRight: 'auto', width: 120 }}
      variant="contained"
      size="small"
      color="primary"
      onClick={() => props.history.push('/support/faqs')}
    >
      {'< Go back'}
    </Button>
    <div className={props.classes.width}>
      <ReferenceArrayInput label="Spaces assignment (optional)" source="spaceIds" reference="spaces">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
    </div>
    <ReferenceInput
      label="Category assignment"
      source="categoryId"
      reference="support/faq_categories"
      validate={[required()]}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <LongTextInput source="q" label="Question" validate={[required()]} />
    <LongTextInput source="a" label="Answer" validate={[required()]} />
  </SimpleForm>
);

export default withRouter(withStyles(styles)(FaqsForm));
