import { createMuiTheme } from '@material-ui/core/styles';

export const yoursTheme = createMuiTheme({
  palette: {

    primary: {
      main: 'rgb(37, 179, 176)',
      light: 'rgb(37, 179, 176)',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      main: 'rgb(37, 179, 176)',
      light: 'rgb(37, 179, 176)',
      dark: '#000000',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'HalisR-Medium',
      'Work Sans',
      '-apple-system',
      'Helvetica Neue',
      'sans-serif'
    ].join(','),
  },
})
