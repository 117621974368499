import React from 'react';
import { Edit } from 'react-admin';
import CompaniesForm from './CompaniesForm';

const CompaniesEdit = props => (
  <Edit {...props} undoable={false}>
    <CompaniesForm redirect={false} />
  </Edit>
);

export default CompaniesEdit;
