import React from 'react';
import {
  SimpleForm,
  ImageInput,
  BooleanInput,
  ImageField,
  LongTextInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { withRouter } from 'react-router-dom';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const AddonsEditToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </Toolbar>
));

const AddonsForm = ({ ...props }) => (
  <SimpleForm {...props} toolbar={<AddonsEditToolbar />}>
    <Button
      style={{ marginRight: 'auto', width: 120 }}
      variant="contained"
      size="small"
      color="primary"
      onClick={() => props.history.push('/addons')}
    >
      {'< Go back'}
    </Button>
    <ReferenceInput
      label="Category assignment"
      source="categoryId"
      reference="addon_categories"
      validate={[required()]}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <LongTextInput source="name" validate={[required()]} />
    <BooleanInput source="isRecurring" defaultValue={false} label="Is it recurring?" />
    <LongTextInput source="description" validate={[required()]} />
    <LongTextInput source="additionalInfo" />
    <ImageInput multiple source="photos" label="Extra photo" accept="image/*">
      <ImageField source="src" title="title" />
    </ImageInput>
  </SimpleForm>
);

export default withRouter(AddonsForm);
