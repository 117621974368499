import React from 'react';
import truncate from 'lodash/truncate';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Divider, Tab, Tabs } from '@material-ui/core';
import moment from 'moment';
import {
  Filter,
  ExportButton,
  DateInput,
  Datagrid,
  List,
  FunctionField,
  ReferenceArrayField,
  TextField,
  CardActions,
  CreateButton,
  Link,
  Button as RaButton,
} from 'react-admin';
import { Button } from '@material-ui/core';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import { withStyles } from '@material-ui/core/styles';
import ContentCreate from '@material-ui/icons/AddToPhotos';
import { pretifyAndShortenColleciton } from '../utils';
import BulkExportButton from '../_common/components/BulkExportButton';

const styles = {
  '@global': {
    'th:first-of-type': {
      width: '90px',
    },
  },
};

const AlertsListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  push,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <Button size="small" color="primary" onClick={() => push('/member_groups')}>
      Create/remove recipients groups
    </Button>
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </CardActions>
);

const _selectedAlert = (selectedIds, alerts) => {
  if (selectedIds.length === 1) {
    return alerts[selectedIds[0]];
  }
  return null;
};

const AlertsActionButtons = ({ alerts, ...props }) => (
  <>
    <CloneButton alert={_selectedAlert(props.selectedIds, alerts)} />
    <BulkExportButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </>
);

const AlertsFilters = props => (
  <Filter {...props}>
    <DateInput source="from" label="From" />
    <DateInput source="to" label="To" />
  </Filter>
);

const CloneButton = ({ alert }) => {
  if (alert) {
    return (
      <RaButton
        component={Link}
        onClick={e => e.stopPropagation()}
        to={{
          pathname: 'alerts/create',
          state: { record: omit(alert, ['_id', 'id']) },
        }}
        label="Clone"
      >
        <ContentCreate />
      </RaButton>
    );
  }
  return null;
};

const JoinedMemberCollectionField = ({ data, all }) => {
  if (all) {
    return <div>All</div>;
  }
  return <div>{pretifyAndShortenColleciton(data, ['firstName', 'lastName'])}</div>;
};

const JoinedSpaceCollectionField = ({ data }) => <div>{pretifyAndShortenColleciton(data, ['name'], 1)}</div>;

const Members = props => (
  <ReferenceArrayField {...props} source="memberIds" reference="members" label="Recipients" sortable={false}>
    <JoinedMemberCollectionField all={props.record.all} />
  </ReferenceArrayField>
);

const Row = props => (
  <Datagrid rowClick="edit" {...props}>
    <TextField source="name" label="Title" />
    <FunctionField
      source="content"
      label="Message content"
      render={record => truncate(record.content, { length: 50 })}
      sortable={false}
    />
    <FunctionField
      source="date"
      sortBy="date"
      label="Date"
      render={record => moment(record.date).format('DD MMM YYYY HH:mm')}
    />
    <Members label="Recipients" />
    <ReferenceArrayField source="spaceIds" reference="spaces" label="Business" sortable={false}>
      <JoinedSpaceCollectionField />
    </ReferenceArrayField>
    <TextField source="type" />
  </Datagrid>
);

class TabbedDatagrid extends React.Component {
  tabs = [
    { id: 'live', name: 'live alerts' },
    { id: 'past', name: 'past alerts' },
  ];
  state = { live: [], past: [] };

  static getDerivedStateFromProps(props, state) {
    if (props.ids !== state[props.filterValues.status]) {
      return { ...state, [props.filterValues.status]: props.ids };
    }
    return null;
  }

  handleChange = (event, value) => {
    const { filterValues, setFilters } = this.props;
    setFilters({ ...filterValues, status: value });
  };

  render() {
    const { filterValues, ...props } = this.props;
    return (
      <>
        <Tabs value={filterValues.status} indicatorColor="primary" onChange={this.handleChange}>
          {this.tabs.map(choice => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === 'live' && <Row {...props} />}
          {filterValues.status === 'past' && <Row {...props} />}
        </div>
      </>
    );
  }
}

const AlertsList = ({ ...props }) => (
  <List
    {...props}
    filterDefaultValues={{ status: 'live' }}
    actions={<AlertsListActions push={props.history.push} />}
    bulkActionButtons={<AlertsActionButtons alerts={props.alerts} />}
    filters={<AlertsFilters />}
  >
    <TabbedDatagrid />
  </List>
);

const mapStateToProps = state => ({
  alerts: get(state, 'admin.resources.alerts.data', {}),
});

export default withStyles(styles)(connect(mapStateToProps)(AlertsList));
