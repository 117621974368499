import React from 'react';
import moment from 'moment';
import { List, ListItem, Divider, Button, Typography, CircularProgress } from '@material-ui/core';
import { Query } from 'react-admin';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

const DocumentsOverview = ({ spaceId, history: { push } }) => {
  return (
    <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <Typography style={{ fontSize: 24 }}>Documents overview</Typography>
        <Button onClick={() => push('/documents')} variant="contained">
          Edit documents
        </Button>
      </div>
      <Query
        type="GET_MANY"
        resource="documents"
        payload={{
          filters: {
            spaceId,
          },
          limit: 5,
        }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <CircularProgress style={{ margin: '10px auto' }} />;
          }
          return (
            <List component="nav">
              {isEmpty(data) && !loading && (
                <div>The list is empty</div>
              )}
              {data.map(item => (
                <>
                  <ListItem button onClick={() => push(`/documents/${item._id}`)}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                      <span>{item.name}</span>
                      <span>{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</span>
                    </div>
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
          );
        }}
      </Query>
    </div>
  );
};

export default withRouter(DocumentsOverview);
