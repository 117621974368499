import React from 'react';
import {
  required,
  SelectInput,
  SimpleForm,
  SaveButton,
  TextInput,
  FileInput,
  FileField,
  Toolbar,
} from 'react-admin';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const formStyles = {
  xeroButton: {
    marginTop: 30,
    marginBottom: 15,
  },
  xeroControls: {
    display: 'flex',
    flexDirection: 'column',
  },
  privateKey: {
    marginTop: 15,
    fontFamily: 'HalisR-Medium, sans-serif',
  },
};

const XeroConfigsToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props} />
  </Toolbar>
));

const XeroConfigsForm = ({ formValues, classes, ...props }) => {
  return (
    <SimpleForm {...props} redirect={basePath => basePath} toolbar={<XeroConfigsToolbar />}>
      <TextInput source="name" validate={[required()]} />
      <SelectInput
        source="appType"
        label="Xero app type"
        choices={[{ id: 'private', name: 'Private' }, { id: 'public', name: 'Public' }]}
        validate={[required()]}
      />
      <TextInput source="consumerKey" label="Xero consumer key" validate={[required()]} />
      <TextInput source="consumerSecret" label="Xero consumer secret" validate={[required()]} />
      <FileInput source="privateKey" label="Xero credential file (.pem)" accept=".pem">
        <FileField source="src" title="title" />
      </FileInput>
      {formValues.privateKeyString && (
        <div className={classes.privateKey}>
          <div>Current key:</div>
          {`${formValues.privateKeyString.substring(0, 55)}***`}
        </div>
      )}
    </SimpleForm>
  );
};

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = compose(
  connect(mapStateToProps),
  withStyles(formStyles),
);

export default enhance(XeroConfigsForm);
