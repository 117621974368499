import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import isEqual from 'lodash/isEqual';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import API, { deleteGlobalSpaceIdsParams, setGlobalSpaceIdsParams } from '../../_app/api';
import { API_ROUTES } from '../../_app/routes';
import { setGlobalSpaceIds } from '../../sideEffects/globalFilters/globalFiltersActions';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '5px',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class GlobalFilters extends React.Component {
  state = {
    options: [],
  };

  async componentDidMount() {
    this.fetchSpaces();
  }

  componentDidUpdate(prevProps) {
    if (this.props.totalSpaces > 0 && prevProps.totalSpaces !== this.props.totalSpaces) {
      this.fetchSpaces();
    }
    if (!isEqual(this.props.slectedSpaceIds, prevProps.slectedSpaceIds)) {
      this.props.refreshView();
    }
  }

  fetchSpaces = async () => {
    try {
      const {
        data: { data: options },
      } = await API(`${API_ROUTES.SPACES}?limit=50&order=ASC&sort=name`);
      this.setState({ options });
    } catch (e) {
      this.props.setGlobalSpaceIds([]);
      deleteGlobalSpaceIdsParams();
    }
  };

  handleChange = event => {
    const {
      location: { pathname },
      push,
    } = this.props.history;
    if (last(event.target.value) === 'all' || event.target.value === []) {
      this.props.setGlobalSpaceIds([]);
      deleteGlobalSpaceIdsParams();
      if (pathname.includes('/spaces')) {
        push('/spaces');
      }
    } else {
      const filteredData = event.target.value.filter(spaceId => spaceId !== 'all');
      this.props.setGlobalSpaceIds(filteredData);
      setGlobalSpaceIdsParams(filteredData);
      if (pathname.includes('/spaces')) {
        if (filteredData.length === 1) {
          push(`/spaces/${filteredData[0]}`);
        }
        if (filteredData.length > 1) {
          push('/spaces');
        }
      }
    }
  };

  render() {
    const { classes, slectedSpaceIds } = this.props;
    const { options } = this.state;
    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="filter">Filter</InputLabel>
          <Select value={isEmpty(slectedSpaceIds) ? ['all'] : slectedSpaceIds} onChange={this.handleChange} multiple>
            <MenuItem value="all">All</MenuItem>
            {options.map(({ _id, name }, idx) => (
              <MenuItem key={`global-filter-${idx}`} value={_id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
}

GlobalFilters.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  totalSpaces: get(state.admin.resources, 'spaces.list.total', 0),
  slectedSpaceIds: state.globalFilters.spaceIds,
});

const mapDispatchToProps = { setGlobalSpaceIds };

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(GlobalFilters));
