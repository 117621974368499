import Button from '@material-ui/core/Button';
import Attachment from '@material-ui/icons/Attachment';
import React from 'react';
import { BooleanField, CardActions, CreateButton, Datagrid, ExportButton, Filter, List, TextField, TextInput, ReferenceInput, AutocompleteInput, ReferenceField, FunctionField } from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import BulkExportButton from '../_common/components/BulkExportButton';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';

const CardsFilters = props => (
  <Filter {...props}>
    <TextInput label="Serial #" source="serialNumber" />
    <TextInput source="token" />
    {/* <ReferenceInput label="Workspace" source="spaceId" reference="spaces" alwaysOn>
        <AutocompleteInput optionText="name" />
    </ReferenceInput> */}
  </Filter>
);

const CardsActionButtons = props => (
  <>
    <BulkExportButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </>
);

const CardsListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  push
}) => (
  <CardActions>
    {bulkActions && React.cloneElement(bulkActions, {
      basePath,
      filterValues,
      resource,
      selectedIds,
      onUnselectItems,
    })}
    {filters && React.cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    }) }
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <Button size="small" color="primary" onClick={() => push('/cardsImports/create')}>
      <Attachment style={{ width: 20, height: 20, marginRight: 6.5 }} />
      Import
    </Button>
  </CardActions>
);

export const CardsList = props => (
  <List {...props} filters={<CardsFilters />} actions={<CardsListActions push={props.push}/>} bulkActionButtons={<CardsActionButtons />}>
    <Datagrid rowClick="edit">
      <TextField source="serialNumber" />
      <TextField source="token" label="Hex token (from manufacturer)"/>
      <TextField source="tokenDecimal" label="Decimal token (for ACT)" />
      <BooleanField source="taken" valueLabelTrue="in use" valueLabelFalse="not in use" />
      <ReferenceField linkType={false} label="Owner" source="memberId" reference="members" allowEmpty>
        <FunctionField label="lastName" render={record => `${record.firstName} ${record.lastName}`} />
      </ReferenceField>
      <ReferenceField linkType={false} label="Workspace" source="spaceId" reference="spaces" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField linkType={false} label="Description" source="spaceId" reference="spaces" allowEmpty>
        <TextField source="description" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default connect(null, { push })(CardsList);
