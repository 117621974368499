export const SET_GLOBAL_SPACE_IDS = 'SET_GLOBAL_SPACE_IDS';
export const TOGGLE_GLOBAL_SPACE_ID = 'TOGGLE_GLOBAL_SPACE_ID';

export const setGlobalSpaceIds = spaceIds => ({
  type: SET_GLOBAL_SPACE_IDS,
  spaceIds,
});

export const toggleGlobalSpaceId = (checked, spaceId) => ({
  type: TOGGLE_GLOBAL_SPACE_ID,
  spaceId,
  checked,
});
