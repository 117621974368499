import React from 'react';
import moment from 'moment';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import {
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  TextInput,
  NumberInput,
  LongTextInput,
  required,
  minValue,
  Toolbar,
  SaveButton
} from 'react-admin';
import { TimeInput } from 'react-admin-date-inputs';
import DeleteButtonWithConfirmation from "../layout/DeleteButtonWithConfirmation";
import { withStyles } from '@material-ui/core/styles';
import { getTimezone } from '../utils';

const validate = ({ from, to }) => {
  const errors = {};
  if (moment(from).isAfter(moment(to))) {
    errors.from = ['Start time must be before end time'];
  }
  return errors;
};

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const ResourcesEditToolbar = withStyles(styles)(({ classes, ...props}) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props}/>
    <DeleteButtonWithConfirmation {...props} />
  </Toolbar>
));

const ResourcesForm = ({ formValues, ...props }) => (
  <SimpleForm {...props} validate={validate} toolbar={<ResourcesEditToolbar/>}>
    <Button
      style={{ marginRight: 'auto', width: 120 }}
      variant="contained"
      size="small"
      color="primary"
      onClick={() => props.history.push('/resources')}
    >
      {'< Go back'}
    </Button>
    <ReferenceInput
      label="Workspace"
      source="spaceId"
      reference="spaces"
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput source="name" validate={[required()]} />
    {/* <NumberInput
      source="pricePerHour"
      validate={[required(), minValue(0)]}
      inputProps={{
        min: 0,
      }}
    /> */}

    {/* <NumberInput source="taxRate" validate={[required(), minValue(0)]} inputProps={{ min: 0 }} /> */}
    <LongTextInput source="description" validate={[required()]} />
    {/* <NumberInput
      source="cancellationPeriod"
      validate={[required(), minValue(0)]}
      inputProps={{
        min: 0,
      }}
    /> */}

    <NumberInput
      validate={[required(), minValue(1)]}
      source="capacity"
      inputProps={{
        min: 1,
      }}
    />
    <NumberInput
      label="Min booking duration (in mins)"
      source="minBookingDuration"
      inputProps={{
        step: 15,
        min: 15,
      }}
    />
    <NumberInput
      label="Max booking duration (in mins)"
      source="maxBookingDuration"
      inputProps={{
        step: 15,
        min: 15,
      }}
    />
    <TimeInput
      label={`Booking times (start) ${getTimezone()}`}
      source="from"
      validate={[required()]}
    />
    <TimeInput
      label={`Booking times (end) ${getTimezone()}`}
      source="to"
      validate={[required()]}
    />
    {/* <ImageInput source="photo" label="Photo" accept="image/*">
      <ImageField source="src" title="title" />
    </ImageInput>
    {!get(formValues, 'photo.src') && (
      <ImageField source="photo" label={null} />
    )} */}
  </SimpleForm>
);

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = compose(withRouter, connect(mapStateToProps));

export default enhance(ResourcesForm);
