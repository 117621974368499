import React from 'react';
import { Create } from 'react-admin';
import FaqsForm from './FaqCategoriesForm';

const FaqsCategoriesCreate = props => (
  <Create {...props}>
    <FaqsForm redirect="edit" />
  </Create>
);

export default FaqsCategoriesCreate;
