import React from 'react';
import { Edit } from 'react-admin';
import MemberGroupsForm from './MemberGroupsForm';

const MemberGroupsEdit = props => (
  <Edit {...props} undoable={false}>
    <MemberGroupsForm redirect={false} />
  </Edit>
);

export default MemberGroupsEdit;
