import axios from 'axios';
import qs from 'query-string';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { DEFAULT_SPACE_TOKEN_KEY } from './constants';

const { REACT_APP_RAICHU_URL: API_URL } = process.env;

const API = axios.create({
  baseURL: API_URL,
  paramsSerializer: params => {
    const { spaceIds, filters, ...restParams } = params;
    return qs.stringify({ ...restParams, filters: JSON.stringify({ spaceIds, ...filters }) });
  },
});

const ACCESS_TOKEN_KEY = 'raichu-token';

export const setGlobalSpaceIdsParams = spaceIds => {
  API.defaults.params = {
    ...API.defaults.params,
    spaceIds,
  };
  localStorage.setItem(DEFAULT_SPACE_TOKEN_KEY, JSON.stringify(spaceIds));
};

export const toggleGlobalSpaceIdParam = (add, spaceId) => {
  if (add) {
    const spaceIds = [...get(API, 'defaults.params.spaceIds', []), spaceId];
    return setGlobalSpaceIdsParams(spaceIds);
  }
  const spaceIds = [...get(API, 'defaults.params.spaceIds', []).filter(param => param !== spaceId)];
  return setGlobalSpaceIdsParams(spaceIds);
};

export const deleteGlobalSpaceIdsParams = () => {
  localStorage.removeItem(DEFAULT_SPACE_TOKEN_KEY);
  API.defaults.params = omit(API.defaults.params || {}, 'spaceIds');
};

export const getToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

export const setToken = ({ token }) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
  API.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const clearToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  delete API.defaults.headers.common.Authorization;
};

const AUTH_TOKEN = getToken();
if (AUTH_TOKEN) {
  API.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
}

export default API;
