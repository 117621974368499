import React, { Fragment } from 'react';
import moment from 'moment';
import { FunctionField, Datagrid, List, TextField } from 'react-admin';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import BulkExportButton from '../_common/components/BulkExportButton';

const PoliciesActionButtons = props => (
  <Fragment>
    <BulkExportButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </Fragment>
);

const PoliciesList = props => (
  <List {...props} bulkActionButtons={<PoliciesActionButtons />}>
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <TextField source="type" />
      <FunctionField
        sortBy="createdAt"
        label="Created at"
        render={record => moment(record.createdAt).format('DD.MM.YYYY HH:mm')}
      />
    </Datagrid>
  </List>
);
export default PoliciesList;
