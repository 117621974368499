import React from 'react';
import { Edit } from 'react-admin';
import BookingsForm from './BookingsForm';

const BookingsEdit = props => (
  <Edit {...props} undoable={false}>
    <BookingsForm {...props} redirect={false} />
  </Edit>
);

export default BookingsEdit;
