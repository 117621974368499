import React from 'react';
import { List, SelectInput, Datagrid, FunctionField, ReferenceInput, AutocompleteInput, TextField, Filter, TextInput, ReferenceField } from 'react-admin';
import moment from 'moment';
import BulkExportButton from '../_common/components/BulkExportButton';

const InvoicesFilters = props => (
  <Filter {...props}>
    <TextInput source="InvoiceNumber" />
    <SelectInput
      source="status"
      choices={[
        {id: 'paid', name: 'paid'},
        {id: 'pending', name: 'pending'},
        {id: 'voided', name: 'voided'},
      ]}
    />
    {/* <ReferenceInput label="Workspace" source="spaceId" reference="spaces" alwaysOn>
        <AutocompleteInput optionText={choice => choice.name}/>
    </ReferenceInput> */}
  </Filter>
);

const InvoicesActionButtons = props => (
  <BulkExportButton {...props} />
);

const InvoicesList = props => (
  <List {...props} filters={<InvoicesFilters />} bulkActionButtons={<InvoicesActionButtons />} sort={{ field: '_id', order: 'DESC'}}>
    <Datagrid rowClick="show">
      <TextField source="InvoiceNumber" />
      <ReferenceField linkType={false} allowEmpty source="spaceId" reference="spaces" label="Workspace">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="status" />
      <FunctionField label="Amount Due" render={record => (`${record.CurrencyCode}${record.AmountDue}`)} />
      <FunctionField label="Amount Paid" render={record => (`${record.CurrencyCode}${record.AmountPaid}`)} />
      <FunctionField label="Date" render={record => moment(record.DateString).format('YYYY-MM-DD')} />
      <FunctionField label="Due Date" render={record => moment(record.DueDateString).format('YYYY-MM-DD')} />

    </Datagrid>
  </List>
);

export default InvoicesList;
