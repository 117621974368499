import React from 'react';
import { Create } from 'react-admin';
import ResourcesForm from './ResourcesForm';

const ResourcesCreate = props => (
  <Create {...props}>
    <ResourcesForm redirect="edit" />
  </Create>
);

export default ResourcesCreate;
