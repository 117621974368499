import React from 'react';
import moment from 'moment';
import { SimpleForm, FunctionField, Toolbar, SaveButton, ReferenceField, TextField } from 'react-admin';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import classnames from 'classnames';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Button } from '@material-ui/core';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const formStyles = {
  heading: {
    fontSize: 30,
    marginTop: 20,
    marginBottom: 10,
  },
  chatWrapper: {
    width: '100%',
    maxWidth: 1000,
  },
  bubble: {
    border: '1px solid gray',
    borderRadius: 5,
    width: '60%',
    padding: 10,
    marginBottom: 15,
  },
  bubbleTs: {
    fontSize: 10,
  },
  userBubble: {
    marginLeft: 'auto',
    backgroundColor: 'rgb(37, 179, 176)',
    color: '#fff',
  },
};

const ChatEditToolbar = withStyles(styles)(({ classes, ...props }) => (
  <div />
  // <Toolbar className={classes.toolbar} {...props}>
  //   <SaveButton {...props} />
  //   <DeleteButtonWithConfirmation {...props} />
  // </Toolbar>
));

const ChatForm = ({ formValues, classes, ...props }) => {
  return (
    <SimpleForm {...props} redirect={basePath => basePath} toolbar={<ChatEditToolbar />}>
      <Button
        style={{ marginRight: 'auto', width: 120 }}
        variant="contained"
        size="small"
        color="primary"
        onClick={() => props.history.push('/chats')}
      >
        {'< Go back'}
      </Button>
      <TextField source="group.name" label="Channel name" />
      <ReferenceField label="Member" source="userId" reference="members">
        <FunctionField label="" render={({ email, firstName, lastName }) => `${firstName} ${lastName} <${email}>`} />
      </ReferenceField>
      <div className={classes.heading}>Chat history</div>
      <div className={classes.chatWrapper}>
        {(formValues.messages || []).map((message, idx) => (
          <div
            key={`chat-message-${idx}`}
            className={classnames(classes.bubble, message.actor === 'user' && classes.userBubble)}
          >
            <div className={classes.bubbleTs}>{moment(message.ts).format('DD MMM YYYY, HH:mm')}</div>
            <div>{message.text}</div>
          </div>
        ))}
      </div>
    </SimpleForm>
  );
};

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = compose(withRouter, withStyles(formStyles), connect(mapStateToProps));

export default enhance(ChatForm);
