import React from 'react';
import { SimpleForm, TextInput, required, Toolbar, SaveButton } from 'react-admin';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const AddonEditToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </Toolbar>
));

const AddonCategories = ({ ...props }) => (
  <SimpleForm {...props} toolbar={<AddonEditToolbar />}>
    <Button
      style={{ marginRight: 'auto', width: 120 }}
      variant="contained"
      size="small"
      color="primary"
      onClick={() => props.history.push('/addon_categories')}
    >
      {'< Go back'}
    </Button>
    <TextInput source="name" validate={[required()]} />
  </SimpleForm>
);

export default withRouter(AddonCategories);
